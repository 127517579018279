import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { GetClientUser } from "../../../store/userReducers";
import ClipLoader from "react-spinners/ClipLoader";

function Lead() {
  const dispatch = useDispatch();
  const [lead, setLead] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  //   const { ClientUsers } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(GetClientUser());
  }, [dispatch]);

  useEffect(() => {
    GetData();
  }, [page]);

  const GetData = () => {
    setLoading(true);
    fetch(`https://admin.digitalskillup.in/lead?page=${page}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLead(data?.leads);
        setTotalPage(data?.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div style={{ padding: "10px 2%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "default",
          }}
        >
          <h2>Student</h2>
          <div
            style={{
              padding: "8px 15px",
              background: "black",
              color: "white",
              fontWeight: "500",
              borderRadius: "3px",
              cursor: "default",
            }}
          >
            Page-{page}
          </div>
          {/* <div onClick={() => navigate("/admin-user/add")} style={{ padding: "8px 15px", background: "#111827", color: "white", fontWeight: "500", borderRadius: "3px", cursor: "pointer" }}>Add New Admin</div> */}
        </div>
        <div style={{ overflow: "auto" }}>
          <table
            style={{ width: "100%", marginTop: "20px", cursor: "default" }}
          >
            <thead
              style={{ backgroundColor: "rgb(176 204 213)", color: "white" }}
            >
              <tr>
                <th
                  style={{
                    padding: "10px",
                    width: "70px",
                    color: "rgba(91, 89, 89)",
                  }}
                >
                  NO.
                </th>
                <th
                  style={{
                    padding: "10px 20px",
                    whiteSpace: "nowrap",
                    color: "rgba(91, 89, 89)",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    padding: "10px 20px",
                    whiteSpace: "nowrap",
                    color: "rgba(91, 89, 89)",
                  }}
                >
                  Course
                </th>
                <th
                  style={{
                    padding: "10px 20px",
                    whiteSpace: "nowrap",
                    color: "rgba(91, 89, 89)",
                  }}
                >
                  Email
                </th>
                <th
                  style={{
                    padding: "10px 20px",
                    whiteSpace: "nowrap",
                    color: "rgba(91, 89, 89)",
                  }}
                >
                  Details
                </th>
                <th
                  style={{
                    padding: "10px 20px",
                    whiteSpace: "nowrap",
                    color: "rgba(91, 89, 89)",
                  }}
                >
                  Phone
                </th>
                <th
                  style={{
                    padding: "10px 20px",
                    whiteSpace: "nowrap",
                    color: "rgba(91, 89, 89)",
                  }}
                >
                  Status
                </th>
                {/* <th
                  style={{
                    padding: "10px 20px",
                    whiteSpace: "nowrap",
                    color: "rgba(91, 89, 89)",
                  }}
                >
                  Time
                </th> */}
              </tr>
            </thead>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  left: "55%",
                }}
              >
                <ClipLoader
                  color={"#FFC1CC"}
                  loading={loading}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <tbody style={{ color: "white" }}>
                {lead?.map((e, i) => (
                  <TableDate e={e} i={i} />
                ))}
              </tbody>
            )}
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "30px",
            gap: "10px",
          }}
        >
          {page === 1 ? (
            <div
              style={{
                padding: "5px",
                textAlign: "center",
                width: "110px",
                backgroundColor: "lightgray",
                color: "#fff",
                // fontFamily: "poppins",
                borderRadius: "5px",
                fontWeight: "600",
              }}
            >
              <p>Previous</p>
            </div>
          ) : (
            <div
              style={{
                padding: "5px",
                textAlign: "center",
                width: "110px",
                backgroundColor: "rgb(176 204 213)",
                color: "#000",
                borderRadius: "5px",
                fontWeight: "600",
              }}
              onClick={() => setPage(page - 1)}
            >
              <p>Previous</p>
            </div>
          )}
          {page === totalPage ? (
            <div
              style={{
                padding: "5px",
                textAlign: "center",
                width: "110px",
                backgroundColor: "lightgray",
                color: "#fff",
                borderRadius: "5px",
                fontWeight: "600",
              }}
            >
              <p>Next</p>
            </div>
          ) : (
            <div
              style={{
                padding: "5px",
                textAlign: "center",
                width: "110px",
                backgroundColor: "rgb(176 204 213)",
                color: "#000",
                borderRadius: "5px",
                fontWeight: "600",
              }}
              onClick={() => setPage(page + 1)}
            >
              <p>Next</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const TableDate = ({ e, i }) => {
  const navigate = useNavigate();
  const [name, setName] = useState(e.name);
  // const [details, setDetails] = useState(e.description);
  const [number, setNumber] = useState(e.number);
  const [email, setEmail] = useState(e.email);
  const [description, setDescription] = useState(e.description);
  const [course, setCourse] = useState(e.CourseName);
  const [price, setPrice] = useState(e.price);
  const [verify, setVerify] = useState(e.verify);

  console.log("e?.verify", verify);

  return (
    <>
      <tr>
        <td
          style={{
            backgroundColor: "#F1F3FFFF",
            padding: "10px",
            textAlign: "center",
            width: "70px",
            color: "rgba(91, 89, 89)",
          }}
        >
          {i + 1}
        </td>
        <td
          style={{
            backgroundColor: "#F1F3FFFF",
            padding: "10px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <p
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "18px",
              color: "rgba(91, 89, 89)",
            }}
          >
            {name}
          </p>
        </td>
        <td
          style={{
            backgroundColor: "#F1F3FFFF",
            padding: "10px",
            textAlign: "center",
            width: "23%",
          }}
        >
          <p
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "18px",
              color: "rgba(91, 89, 89)",
            }}
          >
            {course}
          </p>
        </td>
        <td
          style={{
            backgroundColor: "#F1F3FFFF",
            padding: "10px",
            textAlign: "center",
            width: "23%",
          }}
        >
          <p
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "18px",
              color: "rgba(91, 89, 89)",
            }}
          >
            {email}
          </p>
        </td>
        <td
          style={{
            backgroundColor: "#F1F3FFFF",
            padding: "10px",
            textAlign: "center",
            width: "23%",
          }}
        >
          <p
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "18px",
              color: "rgba(91, 89, 89)",
            }}
          >
            {description}
          </p>
        </td>
        <td
          style={{
            backgroundColor: "#F1F3FFFF",
            padding: "10px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <p
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "18px",
              color: "rgba(91, 89, 89)",
            }}
          >
            {number}
          </p>
        </td>{" "}
        <td
          style={{
            backgroundColor: "#F1F3FFFF",
            padding: "10px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <p
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              // background: "none",
              boxShadow: "none",
              fontSize: "14px",
              padding: "4px 0px",
              color: "rgba(91, 89, 89)",
              backgroundColor: "rgb(176 204 213 / 51%)",
              border: "1px solid rgb(176 204 213)",
              margin: "0px 30px",
            }}
          >
            {verify == true ? "Verified" : "Not Verified"}
          </p>
        </td>{" "}
        {/* <td
          style={{
            backgroundColor: "#F1F3FFFF",
            padding: "10px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <p
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "18px",
              color: "rgba(91, 89, 89)",
            }}
          >
            {e.time}
          </p>
        </td>{" "} */}
        {/* <td
          style={{
            backgroundColor: "#F1F3FFFF",
            padding: "10px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <FaEye
            onClick={() => navigate(`/lead/${e._id}`)}
            style={{ cursor: "pointer" }}
          />
        </td> */}
      </tr>
    </>
  );
};
export default Lead;
