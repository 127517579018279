import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios'

export const AddExams = createAsyncThunk(
    'AddExam',
    async (body) => {
        try {
            const response = await axios.post(` http://localhost:8000/api/v1/exam/new`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const GetExam = createAsyncThunk(
    'GetExams',
    async ({ _id }) => {
        try {
            let response
            if (_id) {
                response = await axios.get(` http://localhost:8000/api/v1/exam?_id=${_id}`)
            }
            else {
                response = await axios.get(` http://localhost:8000/api/v1/exam`)
            }
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const DelExam = createAsyncThunk(
    'DelExam',
    async (body) => {
        try {
            const response = await axios.post(` http://localhost:8000/api/v1/exam/del`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const UpdateExam = createAsyncThunk(
    'UpdateExam',
    async (body) => {
        try {
            const response = await axios.post(` http://localhost:8000/api/v1/exam/update`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const examBlog = createAsyncThunk(
    'examBlog',
    async (body) => {
        const response = await axios.post(` http://localhost:8000/api/v1/exam/examBlog`, body)
        return response.data

    }
)
export const deleteBlog = createAsyncThunk(
    'deleteBlog',
    async (body) => {
        const response = await axios.post(` http://localhost:8000/api/v1/exam/blogDelete`, body)
        return response.data

    }
)
export const approveExam = createAsyncThunk(
    'approveExam',
    async (body) => {
        try {
            const response = await axios.post(` http://localhost:8000/api/v1/exam/approveBlog`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }
    }
)



const ExamSlice = createSlice({
    name: "user",
    initialState: { exam: [] },
    reducers: {

    },
    extraReducers: {
        [AddExams.fulfilled]: (state, { payload }) => {
            state.exam = payload.exam
        }, [DelExam.fulfilled]: (state, { payload }) => {
            state.exam = payload.exam
        },
        [GetExam.fulfilled]: (state, { payload }) => {
            state.exam = payload.exam
        }, [UpdateExam.fulfilled]: (state, { payload }) => {
            state.exam = payload.exam
        }, [approveExam.fulfilled]: (state, { payload }) => {
            state.exam = payload.exam
        },

    }

}
)
export default ExamSlice.reducer