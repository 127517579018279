import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddSubscription } from "../../store/AdminReducer";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

function BlogAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ckData, setCkData] = useState("");
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState({
    name: "",
    image: null,
    description: "",
  });

  const handleImageChange = (e) => {
    setAdmin({ ...admin, image: e.target.files[0] });
  };

  const handleForm = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const AddBlog = () => {
    if (admin.image === null) {
      alert("please fill in");
      return;
    }
    if (admin.name === "") {
      alert("please fill in");
      return;
    }
    if (admin.description === "") {
      alert("please fill in");
      return;
    }
    if (ckData === "") {
      alert("please fill in");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("photo", admin.image);
    formData.append("name", admin.name);
    formData.append("description", admin.description);
    formData.append("longDecription", ckData);
    axios
      .post("https://admin.digitalskillup.in/blogs", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        alert("Blogs added successfully!");
        setLoading(false);
        // navigate("/course");
      })
      .catch((error) => {
        alert("Failed to add blog. Please try again.");
        console.error("Error:", error);
      });
  };

  return (
    <div style={{ padding: "10px 2%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "rgb(66 123 107)",
        }}
      >
        <h2>Add Blogs</h2>
        <Link
          //   to="/course"
          style={{
            padding: "8px 15px",
            background: "rgb(88 155 136)",
            color: "#fff",
            fontWeight: "500",
            borderRadius: "3px",
            cursor: "pointer",
          }}
        >
          View Blogs
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          // border: "1px solid",
          backgroundColor: "rgb(127 204 182 / 58%)",
          marginTop: "15px",
          padding: "20px",
          gap: "5%",
          flexWrap: "wrap",
          rowGap: "15px",
        }}
      >
        <div style={{ display: "flex", gap: "5%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="title"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "#000",
              }}
            >
              Name
            </label>
            <input
              onChange={handleForm}
              type="text"
              id="name"
              name="name"
              value={admin.name}
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="title"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "#000",
              }}
            >
              Description
            </label>
            <input
              onChange={handleForm}
              type="text"
              id="description"
              name="description"
              value={admin.description}
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: "5%" }}>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
            // action="/courses"
            // method="post"
            enctype="multipart/form-data"
          >
            <label
              htmlFor="image"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "#000",
              }}
            >
              Image
            </label>
            <input
              // onChange={handleForm}
              type="file"
              id="courseImage"
              name="courseImage"
              onChange={handleImageChange}
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          </form>
        </div>
        <div style={{ marginTop: "30px", gap: "5%" }}>
          <label
            htmlFor="Long Description"
            style={{
              fontWeight: "500",
              fontSize: "20px",
              cursor: "pointer",
              color: "#000",
            }}
          >
            Long Description
          </label>
          <CKEditor
            editor={ClassicEditor}
            data={ckData}
            onChange={(event, editor) => {
              const data = editor.getData();
              setCkData(data);
            }}
          />
        </div>
        {loading ? (
          <div
            style={{
              padding: "6px 12px",
              cursor: "pointer",
            }}
          >
            <ClipLoader
              color={"rgb(66 123 107)"}
              loading={loading}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div
            onClick={AddBlog}
            // onClick={() =>
            //   dispatch(AddSubscription({ ...admin, admin: _id })).then((e) =>
            //     setAdmin({ name: "", description: "", price: "" })
            //   )
            // }
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              gap: "10px",
              maxWidth: "200px",
              flex: "1",
            }}
          >
            <div
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
                boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
                borderWidth: "3px",
                textAlign: "center",
                cursor: "pointer",
                background: "rgb(88 155 136)",
                color: "#fff",
              }}
            >
              Submit
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BlogAdd;
