import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
export const GetClientUser = createAsyncThunk(
    'GetClientUser',
    async () => {
        try {
            const response = await axios.get("http://localhost:8000/api/v1/user")
            return response.data
        } catch (err) {
            return err.response.data
        }
    }
)
export const GetUser = createAsyncThunk(
    'GetUser',
    async ({ id }) => {
        try {
            const response = await axios.get(`http://localhost:8000/api/v1/user/${id}`)
            return response.data
        } catch (err) {
            return err.response.data
        }
    }
)
export const UserAssign = createAsyncThunk(
    'UserAssign',
    async (body) => {
        try {
            const response = await axios.put(`http://localhost:8000/api/v1/user/assign`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }
    }
)
export const SendReport = createAsyncThunk(
    'SendReport',
    async (body) => {
        try {
            const response = await axios.post(`http://localhost:8000/api/v1/report`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }
    }
)
export const GetReport = createAsyncThunk(
    'GetReport',
    async ({ id }) => {
        try {
            const response = await axios.get(`http://localhost:8000/api/v1/report?id=${id}`)
            return response.data
        } catch (err) {
            return err.response.data
        }
    }
)

const UserSlice = createSlice({
    name: "user",
    initialState: { ClientUsers: [], user: {}, report: [], AdminEmail: "" },
    reducers: {

        AddAdminEmail: (state, action) => {
            console.log("check++++", action)
            state.AdminEmail = action.payload;
        },

    },

    extraReducers: (builder) => {
        builder.addCase(GetClientUser.fulfilled, (state, { payload }) => {
            if (payload.success) {
                state.ClientUsers = payload.users
            } else {
                alert(payload.message)
            }
        }).addCase(GetUser.fulfilled, (state, { payload }) => {
            if (payload.success) {
                state.user = payload.user
            } else {
                alert(payload.message)
            }
        }).addCase(UserAssign.fulfilled, (state, { payload }) => {
            if (payload.success) {
                state.user = payload.user
            } else {
                alert(payload.message)
            }
        })
            .addCase(SendReport.fulfilled, (state, { payload }) => {
                if (payload.success) {
                    state.report = payload.reports
                } else {
                    alert(payload.message)
                }
            })
            .addCase(GetReport.fulfilled, (state, { payload }) => {
                if (payload.success) {
                    state.report = payload.reports
                } else {
                    alert(payload.message)
                }
            })

    },

})
export const { AddAdminEmail } = UserSlice.actions;
export default UserSlice.reducer