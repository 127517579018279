import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AddBatch,
  AddSubscription,
  updateSubscription,
} from "../../store/AdminReducer";
import ClipLoader from "react-spinners/ClipLoader";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// import { AddSubscription } from "../../store/AdminReducer";

function BatchModel({ closeModel, id, GetDatafromBatch }) {
  const [courseData, setCourseData] = useState([]);
  const [batchData, setBatchData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { _id } = useSelector((State) => State.admin.admin);
  const [startDate, setStartDate] = useState(null);
  const [batchDetail, setBatchDetails] = useState([]);
  const [admin, setAdmin] = useState({
    courseId: "",
  });

  // console.log("batchData?.timing", batchData?.timing);
  // console.log("checking dateeeeeeeee++++", startDate);
  const formHandel = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  console.log("batchData?.timing++++++", batchData?.timing);

  const dispatch = useDispatch();
  useEffect(() => {
    GetData();
    GetBatch();
    // setAdmin(admin?.courseId);
  }, []);
  useEffect(() => {
    setStartDate(batchData?.timing);
  }, [batchData]);

  const GetData = () => {
    fetch("https://admin.digitalskillup.in/courses/allcourses", {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCourseData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const GetBatch = () => {
    fetch(`https://admin.digitalskillup.in/batch/${id}`, { method: "GET" })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setBatchData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const BatchUpdating = () => {
    if (batchData.name === "") {
      alert("please fill in");
      return;
    }
    if (batchData?.courseID?._id === "") {
      alert("please fill in");
      return;
    }
    if (startDate === "") {
      alert("please fill in");
      return;
    }
    if (batchData.duration === "") {
      alert("please fill in");
      return;
    }
    if (batchData.shift === "") {
      alert("please fill in");
      return;
    }
    setLoading(true);
    const payload = {
      name: batchData.name,
      duration: batchData.duration,
      shift: batchData.shift,
      timing: startDate,
      // courseID: admin?.courseId,
      courseID: batchData?.courseID?._id,
    };
    // console.log("paload");
    fetch(`https://admin.digitalskillup.in/batch/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.message == "Batch updated successfully") {
          setBatchData(response?.Batch);
          GetDatafromBatch();
          setLoading(false);
          console.log("response?.Batch", response?.Batch);
          alert("Batch updated successfully");
          console.log("response", response);
        }
      });
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "0px",
        left: "0px",
        background: "#000000b8",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "70%",
          height: "65%",
          backgroundColor: "white",
          marginTop: "15px",
          padding: "20px",
          //   gap: "15%",
          flexWrap: "wrap",
          rowGap: "15px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "rgb(228 160 54)",
            }}
          >
            <h2>Edit Batch</h2>
          </div>
          <div
            onClick={closeModel}
            style={{
              padding: "8px 15px",
              background: "rgb(228 160 54)",
              color: "#fff",
              fontWeight: "500",
              borderRadius: "3px",
              cursor: "pointer",
            }}
          >
            close
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            // border: "1px solid",
            marginTop: "15px",
            padding: "20px",
            gap: "5%",
            flexWrap: "wrap",
            rowGap: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="Name"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "rgb(91, 89, 89)",
              }}
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={batchData?.name}
              onChange={(e) =>
                setBatchData((prev) => {
                  return { ...prev, [e.target.name]: e.target.value };
                })
              }
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
                color: "rgb(91, 89, 89)",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="Timing"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "rgb(91, 89, 89)",
              }}
            >
              Timing
            </label>
            {/* <input
              type="text"
              id="timing"
              name="timing"
              value={batchData?.timing}
              onChange={(e) =>
                setBatchData((prev) => {
                  return { ...prev, [e.target.name]: e.target.value };
                })
              }
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            /> */}
            <DatePicker
              // selected={moment(startDate).subtract(10, "days").calendar()}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              // wrapperClassName="c1"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="Duration"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "rgb(91, 89, 89)",
              }}
            >
              Duration
            </label>
            <input
              type="text"
              id="duration"
              name="duration"
              value={batchData?.duration}
              onChange={(e) =>
                setBatchData((prev) => {
                  return { ...prev, [e.target.name]: e.target.value };
                })
              }
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="shift"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "rgb(91, 89, 89)",
              }}
            >
              shift
            </label>
            <input
              type="text"
              id="shift"
              name="shift"
              value={batchData?.shift}
              onChange={(e) =>
                setBatchData((prev) => {
                  return { ...prev, [e.target.name]: e.target.value };
                })
              }
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="Course"
              style={{ fontWeight: "500", fontSize: "20px", cursor: "pointer" }}
            >
              Course
            </label>
            <select
              name="courseId"
              id="Course"
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
              // value={admin?.courseId}
              value={batchData?.courseID?._id}
              // onChange={(e) =>
              //   setBatchData((prev) => {
              //     return { ...prev, [e.target.name]: e.target.value };
              //   })
              // }
              onChange={(e) =>
                setBatchData((prev) => {
                  return {
                    ...prev,
                    courseID: {
                      ...prev.courseID,
                      _id: e.target.value, // Assuming the value is directly the _id
                    },
                  };
                })
              }
              // onChange={formHandel}
            >
              <option default>Select Course</option>
              {courseData.map((e) => (
                <option key={e._id} value={e._id}>
                  {e.name}
                </option>
              ))}
            </select>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                flexDirection: "column",
                gap: "10px",
                maxWidth: "200px",
                flex: "1",
              }}
            >
              <ClipLoader
                color={"rgb(228 160 54)"}
                loading={loading}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div
              // onClick={() =>
              //   dispatch(
              //     updateSubscription({
              //       id: batchData._id,
              //       name: batchData.name,
              //       duration: batchData.duration,
              //       timing: batchData.timing,
              //       courseID: admin?.courseId,
              //     })
              //   )
              // }
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                gap: "10px",
                maxWidth: "200px",
                flex: "1",
              }}
              onClick={BatchUpdating}
            >
              <div
                style={{
                  padding: "6px 12px",
                  fontWeight: "400",
                  fontSize: "16px",
                  boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
                  borderWidth: "3px",
                  textAlign: "center",
                  cursor: "pointer",
                  background: "rgb(228 160 54)",
                  color: "#fff",
                }}
              >
                Submit
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BatchModel;
