import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AddSubscription, updateFAQ } from "../../store/AdminReducer";
import axios from "axios";
import { color } from "chart.js/helpers";
import ClipLoader from "react-spinners/ClipLoader";

function CourseFaqModel({ closeModel, id, GetDatafromFAQ }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { _id } = useSelector((State) => State.admin.admin);
  const [CourseFAQData, setCourseFAQData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState({
    image: CourseFAQData?.photo,
  });
  //   console.log("admin?.image", admin?.image);

  useEffect(() => {
    GetData();
  }, []);

  const handleImageChange = (e) => {
    setAdmin({ ...admin, image: e.target.files[0] });
  };

  const handleForm = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const GetData = () => {
    fetch(`https://admin.digitalskillup.in/CourseFaq/${id}`, { method: "GET" })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCourseFAQData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  console.log("course---------------", CourseFAQData);

  // const AddCourse = () => {
  //   const formData = new FormData();
  //   formData.append("photo", admin.image);
  //   formData.append("name", admin.name);
  //   formData.append("description", admin.description);
  //   formData.append("price", admin.price);
  //   console.log("admin.name", admin.name);
  //   console.log("admin.description", admin.description);
  //   console.log("admin.price", admin.price);
  //   console.log("admin.image", admin.image);
  //   axios
  //     .post("https://admin.digitalskillup.in/courses", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     .then(() => {
  //       alert("Course added successfully!");
  //       setAdmin({
  //         name: "",
  //         description: "",
  //         price: "",
  //         image: null,
  //       });
  //     })
  //     .catch((error) => {
  //       alert("Failed to add course. Please try again.");
  //       console.error("Error:", error);
  //     });
  // };
  const updateFAQ = () => {
    if (CourseFAQData.Question === "") {
      alert("please fill in");
      return;
    }
    if (CourseFAQData.Answer === "") {
      alert("please fill in");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("Question", CourseFAQData.Question);
    formData.append("Answer", CourseFAQData.Answer);

    axios
      .put(`https://admin.digitalskillup.in/CourseFaq/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        alert("FAQ updated successfully!");
        setLoading(false);
        GetDatafromFAQ();
      })
      .catch((error) => {
        alert("Failed to add FAQ. Please try again.");
        console.error("Error:", error);
      });
  };
  return (
    <div
      style={{
        position: "fixed",
        top: "0px",
        left: "0px",
        background: "#000000b8",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // zIndex: "20",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
          height: "90%",
          backgroundColor: "white",
          marginTop: "15px",
          padding: "20px",
          gap: "15%",
          flexWrap: "wrap",
          rowGap: "15px",
        }}
      >
        <div
          style={{
            // padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10%",
            rowGap: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "rgb(66 123 107)",
            }}
          >
            <h2> CourseFAQ</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="title"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "#000",
              }}
            >
              Question
            </label>
            <input
              onChange={(e) =>
                setCourseFAQData((prev) => {
                  return { ...prev, [e.target.name]: e.target.value };
                })
              }
              type="text"
              id="Question"
              name="Question"
              value={CourseFAQData?.Question}
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="type"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "#000",
              }}
            >
              Answer
            </label>
            <input
              onChange={(e) =>
                setCourseFAQData((prev) => {
                  return { ...prev, [e.target.name]: e.target.value };
                })
              }
              type="text"
              id="Answer"
              name="Answer"
              value={CourseFAQData?.Answer}
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          </div>
          {loading ? (
            <div
              style={{
                padding: "6px 12px",
                cursor: "pointer",
              }}
            >
              <ClipLoader
                color={"rgb(66 123 107)"}
                loading={loading}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div
              // onClick={() =>
              //   dispatch(
              //     updateFAQ({
              //       id: CourseFAQData._id,
              //       name: CourseFAQData?.name,
              //       description: CourseFAQData?.description,
              //       price: CourseFAQData?.price,
              //       longDecription: ckData,
              //       photo: admin?.image,
              //     })
              //   )
              // }
              onClick={updateFAQ}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                gap: "10px",
                maxWidth: "200px",
                flex: "1",
              }}
            >
              <div
                style={{
                  padding: "6px 12px",
                  fontWeight: "400",
                  fontSize: "16px",
                  boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
                  borderWidth: "3px",
                  textAlign: "center",
                  cursor: "pointer",
                  background: "rgb(88 155 136)",
                  color: "#fff",
                }}
              >
                Submit
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            // height: "40%",
            width: "40%",
            // alignItems: "flex-end",
          }}
        >
          <div
            onClick={closeModel}
            style={{
              padding: "8px 15px",
              background: "rgb(88 155 136)",
              color: "#fff",
              fontWeight: "500",
              borderRadius: "3px",
              cursor: "pointer",
              display: "flex",
              width: "15%",
              position: "relative",
              top: "-10px",
              left: "100%",
            }}
          >
            Close
          </div>
          <div
            style={{
              color: "black",
              overflow: "auto",
              maxHeight: "500px",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default CourseFaqModel;
