import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetRole } from "../../store/RoleReducer";
import { MdEdit } from "react-icons/md";
import {
  DeleteAdmin,
  DeleteSubscription,
  GetSubscription,
  updateCourse,
  updateSubscription,
} from "../../store/AdminReducer";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import SubscriptionAdd from "./SubscriptionAdd";
import CourseModal from "./CourseModal";
import ClipLoader from "react-spinners/ClipLoader";
import CategoryModel from "./CategoryModel";

function CourseCategory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { admin, subscriptions } = useSelector((State) => State.admin);
  const { admin } = useSelector((State) => State.admin);
  const [courseData, setCourseData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  //   {
  //     name: "Digital Marketing",
  //     details: "It will take 3 months",
  //     price: "8986",
  //   },
  //   {
  //     name: "Advance Excel",
  //     details: "It will take 3 months",
  //     price: "6754",
  //   },
  //   {
  //     name: "Canva",
  //     details: "It will take 3 months",
  //     price: "9876",
  //   },
  //   {
  //     name: "Tally",
  //     details: "It will take 3 months",
  //     price: "8976",
  //   },
  // ];

  useEffect(() => {
    GetData();
  }, []);

  const GetData = () => {
    setLoading(true);
    fetch("https://admin.digitalskillup.in/category", { method: "GET" })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCategoryData(data);
        // console.log("categoryData", categoryData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // useEffect(() => {
  //   dispatch(GetSubscription({ admin: admin._id }));
  // }, [dispatch, admin._id]);
  return (
    <>
      <div style={{ padding: "10px 2%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "rgb(88 155 136)",
            cursor: "auto",
          }}
        >
          <h2>Courses</h2>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <div
              onClick={() => navigate("/category/add")}
              style={{
                padding: "8px 15px",
                background: "#7fccb6",
                color: "rgb(91, 89, 89)",
                fontWeight: "500",
                borderRadius: "3px",
                cursor: "pointer",
              }}
            >
              Add New Category
            </div>
          </div>
        </div>
        <div style={{ overflow: "auto" }}>
          <table
            style={{ width: "100%", marginTop: "20px", cursor: "default" }}
          >
            <thead
              style={{
                backgroundColor: "rgb(127 204 182 / 45%)",
                color: "rgb(91, 89, 89)",
              }}
            >
              <tr>
                <th style={{ padding: "10px", width: "70px" }}>NO.</th>
                <th style={{ padding: "10px" }}>Name</th>
                {/* <th style={{ padding: "10px" }}>Type</th> */}
                <th style={{ padding: "10px" }}>Detail</th>
                {/* <th style={{ padding: "10px" }}>Assumed Price</th>
                <th style={{ padding: "10px" }}>Discount%</th>
                <th style={{ padding: "10px" }}>Price</th> */}
                <th style={{ padding: "10px" }}>Delete</th>
                <th style={{ padding: "10px" }}>Edit</th>
              </tr>
            </thead>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  left: "55%",
                }}
              >
                <ClipLoader
                  color={"#FFC1CC"}
                  loading={loading}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <tbody style={{ color: "white" }}>
                {categoryData?.map((e, i) => (
                  <TableDate
                    e={e}
                    i={i}
                    GetData={GetData}
                    setCategoryData={setCategoryData}
                    categoryData={categoryData}
                  />
                ))}
              </tbody>
            )}
          </table>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "30px",
            gap: "10px",
            cursor: "default",
          }}
        >
          {page === 1 ? (
            <div
              style={{
                padding: "5px",
                textAlign: "center",
                width: "110px",
                backgroundColor: "lightgray",
                color: "#fff",
                // fontFamily: "poppins",
                borderRadius: "5px",
                fontWeight: "600",
              }}
            >
              <p>Previous</p>
            </div>
          ) : (
            <div
              style={{
                padding: "5px",
                textAlign: "center",
                width: "110px",
                backgroundColor: "rgb(127 204 182 / 83%)",
                color: "#000",
                borderRadius: "5px",
                fontWeight: "600",
              }}
              onClick={() => setPage(page - 1)}
            >
              <p>Previous</p>
            </div>
          )}
          {page === totalPage ? (
            <div
              style={{
                padding: "5px",
                textAlign: "center",
                width: "110px",
                backgroundColor: "lightgray",
                color: "#fff",
                borderRadius: "5px",
                fontWeight: "600",
              }}
            >
              <p>Next</p>
            </div>
          ) : (
            <div
              style={{
                padding: "5px",
                textAlign: "center",
                width: "110px",
                backgroundColor: "rgb(127 204 182 / 83%)",
                color: "#000",
                borderRadius: "5px",
                fontWeight: "600",
              }}
              onClick={() => setPage(page + 1)}
            >
              <p>Next</p>
            </div>
          )}
        </div> */}
      </div>
    </>
  );
}
const TableDate = ({ e, i, setCategoryData, GetData, categoryData }) => {
  const dispatch = useDispatch();
  const { admin } = useSelector((State) => State.admin);
  const [name, setName] = useState(e.name);
  const [open, setOpen] = useState(false);
  // const [type, setType] = useState(e.type);
  // const [details, setDetails] = useState(e.description);
  const [description, setDescription] = useState(e.description);
  // const [price, setPrice] = useState(
  //   e?.price - (e.price * e.OffpricePercent) / 100
  // );
  const [offPercent, setOffPercent] = useState(e.OffpricePercent);
  const [Asumedprice, setAsumedprice] = useState(e.price);
  const [modal, setModal] = useState(false);

  console.log("categoryData------------", categoryData);

  const closeModel = () => {
    setModal(false);
  };
  useEffect(() => {
    setName(e.name);
    // setType(e.type);
    setDescription(e.description);
    // setPrice(e.price);
  }, [e]);
  const DeleteCategory = () => {
    fetch(`https://admin.digitalskillup.in/category/${e._id}`, {
      method: "DELETE",
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log("hello", response);
        setCategoryData([...response.category]);
        GetData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      {modal && (
        <CategoryModel
          closeModel={closeModel}
          id={e._id}
          GetDatafromCategory={GetData}
        />
      )}
      {open && (
        <div
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            background: "#000000b8",
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "25%",
              height: "20%",
              backgroundColor: "white",
              marginTop: "15px",
              padding: "20px",
              //   gap: "15%",
              flexWrap: "wrap",
              rowGap: "15px",
            }}
          >
            <h1
              style={{
                fontSize: "20px",
                fontWeight: "500",
                textAlign: "center",
                color: "black",
                marginBottom: "10px",
              }}
            >
              Are you sure?
            </h1>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "30px",
              }}
            >
              <div
                style={{
                  background: "gray",
                  padding: "5px 10px",
                  borderRadius: "7px",
                }}
                onClick={DeleteCategory}
              >
                <p>yes</p>
              </div>
              <div
                style={{
                  background: "gray",
                  padding: "5px 10px",
                  borderRadius: "7px",
                }}
                onClick={() => setOpen(false)}
              >
                <p>No</p>
              </div>
            </div>
            <div
              onClick={() => setOpen(false)}
              style={{
                padding: "2px 5px",
                background: "rgb(228 160 54)",
                color: "#fff",
                fontWeight: "500",
                borderRadius: "3px",
                cursor: "pointer",
                position: "absolute",
                top: "42%",
                left: "59%",
                width: "3%",
              }}
            >
              close
            </div>
          </div>
        </div>
      )}
      {categoryData && categoryData.length > 0 ? (
        <tr>
          <td
            style={{
              backgroundColor: "rgb(126 190 174 / 18%)",
              padding: "7px",
              textAlign: "center",
              width: "70px",
              color: "rgb(91, 89, 89)",
              cursor: "default",
            }}
          >
            {i + 1}
          </td>
          <td
            style={{
              backgroundColor: "rgb(126 190 174 / 18%)",
              padding: "7px",
              textAlign: "center",
              width: "30%",
            }}
          >
            <input
              style={{
                textAlign: "center",
                border: "none",
                outline: "none",
                background: "none",
                boxShadow: "none",
                fontSize: "16px",
                width: "180px",
                color: "rgb(91, 89, 89)",
                cursor: "default",
              }}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </td>
          {/* <td
          style={{
            backgroundColor: "rgb(126 190 174 / 18%)",
            padding: "7px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <input
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "16px",
              width: "180px",
              color: "rgb(91, 89, 89)",
              cursor: "default",
            }}
            type="text"
            value={type}
            onChange={(e) => setType(e.target.value)}
          />
        </td> */}
          <td
            style={{
              backgroundColor: "rgb(126 190 174 / 18%)",
              padding: "7px",
              textAlign: "center",
              width: "30%",
            }}
          >
            <input
              style={{
                textAlign: "center",
                border: "none",
                outline: "none",
                background: "none",
                boxShadow: "none",
                fontSize: "16px",
                width: "180px",
                color: "rgb(91, 89, 89)",
                cursor: "default",
              }}
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </td>
          {/* <td
          style={{
            backgroundColor: "rgb(126 190 174 / 18%)",
            padding: "7px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <input
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "16px",
              width: "130px",
              color: "rgb(91, 89, 89)",
              cursor: "default",
            }}
            // type="number"
            value={Asumedprice}
            // onChange={(e) => setPrice(e.target.value)}
          />
        </td>
        <td
          style={{
            backgroundColor: "rgb(126 190 174 / 18%)",
            padding: "7px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <input
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "16px",
              width: "130px",
              color: "rgb(91, 89, 89)",
              cursor: "default",
            }}
            // type="number"
            value={offPercent}
            // onChange={(e) => setPrice(e.target.value)}
          />
        </td>
        <td
          style={{
            backgroundColor: "rgb(126 190 174 / 18%)",
            padding: "7px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <input
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "16px",
              width: "130px",
              color: "rgb(91, 89, 89)",
              cursor: "default",
            }}
            // type="number"
            value={
              e && e.price && e.OffpricePercent
                ? Math.round(e.price - (e.price * e.OffpricePercent) / 100)
                : ""
            }
            // onChange={(e) => setPrice(e.target.value)}
          />
        </td> */}
          <td
            style={{
              backgroundColor: "rgb(126 190 174 / 18%)",
              padding: "7px",
              textAlign: "center",
              width: "100px",
            }}
          >
            <MdDelete
              // onClick={() => {
              //   dispatch(
              //     DeleteSubscription({ id: e._id, admin: admin._id })
              //   ).then((e) => console.log(e));
              // }}
              onClick={() => setOpen(true)}
              size={25}
              style={{ cursor: "pointer", color: "#7fccb6" }}
            />
          </td>

          <td
            style={{
              backgroundColor: "rgb(126 190 174 / 18%)",
              padding: "7px",
              textAlign: "center",
              width: "70px",
            }}
          >
            <button
              style={{
                backgroundColor: "#FFF",
                color: "rgb(91, 89, 89)",
                padding: "6px 10px",
                border: "1px solid #7fccb6",
                outline: "none",
                borderRadius: "3px",
                marginBottom: "3px",
                cursor: "pointer",
              }}
              onClick={() => setModal(true)}
            >
              <MdEdit
                size={17}
                style={{ cursor: "pointer", color: "#7fccb6" }}
              />
            </button>
          </td>
        </tr>
      ) : (
        <h1 style={{ textAlign: "center", marginTop: "20px" }}>
          No Data Available
        </h1>
      )}
    </>
  );
};
export default CourseCategory;
