import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetRole } from "../../store/RoleReducer";
import { MdEdit } from "react-icons/md";
import {
  DeleteAdmin,
  DeleteSubscription,
  GetSubscription,
  updateCourse,
  updateSubscription,
} from "../../store/AdminReducer";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import SubscriptionAdd from "./SubscriptionAdd";
import CourseModal from "./CourseModal";
import ClipLoader from "react-spinners/ClipLoader";
import CategoryModel from "./CategoryModel";
import CourseFaqModel from "./CourseFaqModel";

function CourseFAQ() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { admin, subscriptions } = useSelector((State) => State.admin);
  const { admin } = useSelector((State) => State.admin);
  const [courseData, setCourseData] = useState([]);
  const [CourseFAQ, setCourseFAQ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();

  useEffect(() => {
    GetData();
  }, []);

  const GetData = () => {
    setLoading(true);
    fetch("https://admin.digitalskillup.in/CourseFaq", { method: "GET" })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCourseFAQ(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div style={{ padding: "10px 2%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "rgb(88 155 136)",
            cursor: "auto",
          }}
        >
          <h2>CoursesFAQ</h2>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <div
              onClick={() => navigate("/AddCourseFAQ")}
              style={{
                padding: "8px 15px",
                background: "#7fccb6",
                color: "rgb(91, 89, 89)",
                fontWeight: "500",
                borderRadius: "3px",
                cursor: "pointer",
              }}
            >
              Add New FAQ
            </div>
          </div>
        </div>
        <div style={{ overflow: "auto" }}>
          <table
            style={{ width: "100%", marginTop: "20px", cursor: "default" }}
          >
            <thead
              style={{
                backgroundColor: "rgb(127 204 182 / 45%)",
                color: "rgb(91, 89, 89)",
              }}
            >
              <tr>
                <th style={{ padding: "10px", width: "70px" }}>NO.</th>
                <th style={{ padding: "10px" }}>Question</th>
                <th style={{ padding: "10px" }}>Answer</th>
                <th style={{ padding: "10px" }}>Delete</th>
                <th style={{ padding: "10px" }}>Edit</th>
              </tr>
            </thead>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  left: "55%",
                }}
              >
                <ClipLoader
                  color={"#FFC1CC"}
                  loading={loading}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <tbody style={{ color: "white" }}>
                {CourseFAQ?.map((e, i) => (
                  <TableDate
                    e={e}
                    i={i}
                    GetData={GetData}
                    setCourseFAQ={setCourseFAQ}
                    CourseFAQ={CourseFAQ}
                  />
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
const TableDate = ({ e, i, setCourseFAQ, GetData }) => {
  const dispatch = useDispatch();
  const { admin } = useSelector((State) => State.admin);
  const [question, setQuestion] = useState(e.Question);
  const [answer, setAnswer] = useState(e.Answer);
  const [modal, setModal] = useState(false);

  const closeModel = () => {
    setModal(false);
  };
  useEffect(() => {
    setQuestion(e.Question);
    setAnswer(e.Answer);
  }, [e]);
  const DeleteFAQ = () => {
    fetch(`https://admin.digitalskillup.in/CourseFaq/${e._id}`, {
      method: "DELETE",
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setCourseFAQ([...response.allFAQ]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      {modal && (
        <CourseFaqModel
          closeModel={closeModel}
          id={e._id}
          GetDatafromFAQ={GetData}
        />
      )}
      <tr>
        <td
          style={{
            backgroundColor: "rgb(126 190 174 / 18%)",
            padding: "7px",
            textAlign: "center",
            width: "70px",
            color: "rgb(91, 89, 89)",
            cursor: "default",
          }}
        >
          {i + 1}
        </td>
        <td
          style={{
            backgroundColor: "rgb(126 190 174 / 18%)",
            padding: "7px",
            textAlign: "center",
            width: "60%",
          }}
        >
          <input
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "16px",
              width: "180px",
              color: "rgb(91, 89, 89)",
              cursor: "default",
            }}
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </td>
        <td
          style={{
            backgroundColor: "rgb(126 190 174 / 18%)",
            padding: "7px",
            textAlign: "center",
            width: "60%",
          }}
        >
          <input
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "16px",
              width: "180px",
              color: "rgb(91, 89, 89)",
              cursor: "default",
            }}
            type="text"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </td>
        <td
          style={{
            backgroundColor: "rgb(126 190 174 / 18%)",
            padding: "7px",
            textAlign: "center",
            width: "100px",
          }}
        >
          <MdDelete
            // onClick={() => {
            //   dispatch(
            //     DeleteSubscription({ id: e._id, admin: admin._id })
            //   ).then((e) => console.log(e));
            // }}
            onClick={DeleteFAQ}
            size={25}
            style={{ cursor: "pointer", color: "#7fccb6" }}
          />
        </td>
        <td
          style={{
            backgroundColor: "rgb(126 190 174 / 18%)",
            padding: "7px",
            textAlign: "center",
            width: "70px",
          }}
        >
          <button
            style={{
              backgroundColor: "#FFF",
              color: "rgb(91, 89, 89)",
              padding: "6px 10px",
              border: "1px solid #7fccb6",
              outline: "none",
              borderRadius: "3px",
              marginBottom: "3px",
              cursor: "pointer",
            }}
            onClick={() => setModal(true)}
          >
            <MdEdit size={17} style={{ cursor: "pointer", color: "#7fccb6" }} />
          </button>
        </td>
      </tr>
    </>
  );
};
export default CourseFAQ;
