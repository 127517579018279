import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddSubscription } from "../../store/AdminReducer";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

function CourseCategoryAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ckData, setCkData] = useState("");
  const [courseData, setCourseData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const { _id } = useSelector((State) => State.admin.admin);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState({
    name: "",
    description: "",
    price: "",
    OffpricePercent: "",
    image: null,
  });

  const handleImageChange = (e) => {
    setAdmin({ ...admin, image: e.target.files[0] });
  };

  const handleForm = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  // const handleSubmit = () => {
  //   dispatch(AddSubscription({ ...admin, admin: _id }))
  //     .then(() => {
  //       alert("Course added successfully!");
  //       setAdmin({
  //         name: "",
  //         description: "",
  //         price: "",
  //         image: null,
  //       });
  //     })
  //     .catch((error) => {
  //       alert("Failed to add Course. Please try again.");
  //       console.error("Error:", error);
  //     });
  // };
  // const AddCategory = () => {
  //   axios
  //     .post("https://admin.digitalskillup.in/courses", course)
  //     .then((response) => {
  //       console.log("response of course message", response?.data?.message);
  //       if (response?.data?.message == "Course Successfully Created") {
  //         setCourse({ name: "", description: "", price: "" });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };

  //   useEffect(() => {
  //     GetData();
  //   }, [page]);

  //   const GetData = () => {
  //     fetch(`https://admin.digitalskillup.in/courses?page=${page}`, { method: "GET" })
  //       .then((response) => {
  //         return response.json();
  //       })
  //       .then((data) => {
  //         setCourseData(data?.courses);
  //         setTotalPage(data?.totalPages);
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   };
  const AddCategory = () => {
    if (admin.image === null) {
      alert("please fill in");
      return;
    }
    if (admin.name === "") {
      alert("please fill in");
      return;
    }
    if (admin.description === "") {
      alert("please fill in");
      return;
    }
    // if (admin.price === "") {
    //   alert("please fill in");
    //   return;
    // }
    // if (admin.OffpricePercent === "") {
    //   alert("please fill in");
    //   return;
    // }
    // if (ckData === "") {
    //   alert("please fill in");
    //   return;
    // }
    // if (Number(admin.OffpricePercent) > 100) {
    //   alert("Discount Should be less than 100");
    //   return;
    // }
    setLoading(true);
    const formData = new FormData();
    formData.append("photo", admin.image);
    formData.append("name", admin.name);
    formData.append("description", admin.description);
    // formData.append("type", admin.type);
    // formData.append("price", admin.price);
    // formData.append("OffpricePercent", admin.OffpricePercent);
    // formData.append("longDecription", ckData);
    console.log("formData", formData);
    axios
      .post("https://admin.digitalskillup.in/category", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        alert("Category added successfully!");
        setLoading(false);
        navigate("/category");
      })
      .catch((error) => {
        alert("Failed to add Category. Please try again.");
        console.error("Error:", error);
      });
  };

  return (
    <div style={{ padding: "10px 2%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "rgb(66 123 107)",
        }}
      >
        <h2>Add Category</h2>
        <Link
          to="/category"
          style={{
            padding: "8px 15px",
            background: "rgb(88 155 136)",
            color: "#fff",
            fontWeight: "500",
            borderRadius: "3px",
            cursor: "pointer",
          }}
        >
          View Category
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          // border: "1px solid",
          backgroundColor: "rgb(127 204 182 / 58%)",
          marginTop: "15px",
          padding: "20px",
          gap: "5%",
          flexWrap: "wrap",
          rowGap: "15px",
        }}
      >
        <div style={{ display: "flex", gap: "5%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="title"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "#000",
              }}
            >
              Name
            </label>
            <input
              onChange={handleForm}
              type="text"
              id="name"
              name="name"
              value={admin.name}
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="Short Description"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "#000",
              }}
            >
              Description
            </label>
            <input
              onChange={handleForm}
              type="text"
              id="description"
              name="description"
              value={admin.description}
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="Type"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "#000",
              }}
            >
              Type
            </label>
            <input
              onChange={handleForm}
              type="text"
              id="type"
              name="type"
              value={admin.type}
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          </div> */}
        </div>
        <div style={{ display: "flex", gap: "5%" }}>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
            // action="/courses"
            // method="post"
            enctype="multipart/form-data"
          >
            <label
              htmlFor="image"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "#000",
              }}
            >
              Image
            </label>
            <input
              // onChange={handleForm}
              type="file"
              id="categoryImage"
              name="categoryImage"
              onChange={handleImageChange}
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          </form>
        </div>
        {loading ? (
          <div
            style={{
              padding: "6px 12px",
              cursor: "pointer",
            }}
          >
            <ClipLoader
              color={"rgb(66 123 107)"}
              loading={loading}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div
            onClick={AddCategory}
            // onClick={() =>
            //   dispatch(AddSubscription({ ...admin, admin: _id })).then((e) =>
            //     setAdmin({ name: "", description: "", price: "" })
            //   )
            // }
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              gap: "10px",
              maxWidth: "200px",
              flex: "1",
            }}
          >
            <div
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
                boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
                borderWidth: "3px",
                textAlign: "center",
                cursor: "pointer",
                background: "rgb(88 155 136)",
                color: "#fff",
              }}
            >
              Submit
            </div>
          </div>
        )}
      </div>
      {/* <div
        style={{
          marginTop: "50px",
          // boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
          padding: "20px",
          width: "100%",
        }}
      >
        <div
          style={{
            marginTop: "50px",
            boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
            backgroundColor: "rgb(127 204 182 / 58%)",
            padding: "20px",
            width: "95%",
            maxWidth: "350px",
            margin: "auto",
          }}
        >
          <h1
            style={{
              display: "flex",
              fontWeight: "500",
              justifyContent: "center",
              fontSize: "20px",
              paddingBottom: "10px",
              // marginTop: "15px",
            }}
          >
            Active Courses
          </h1>
          <>
            {courseData.map((e) => {
              return (
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "16px",
                    marginTop: "7px",
                  }}
                >
                  {e?.name}
                </p>
              );
            })}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "30px",
                gap: "10px",
                cursor: "default",
              }}
            >
              {page === 1 ? (
                <div
                  style={{
                    padding: "5px",
                    textAlign: "center",
                    width: "110px",
                    backgroundColor: "lightgray",
                    color: "#fff",
                    // fontFamily: "poppins",
                    borderRadius: "5px",
                    fontWeight: "600",
                  }}
                >
                  <p>Previous</p>
                </div>
              ) : (
                <div
                  style={{
                    padding: "5px",
                    textAlign: "center",
                    width: "110px",
                    backgroundColor: "rgb(127 204 182 / 83%)",
                    color: "#000",
                    borderRadius: "5px",
                    fontWeight: "600",
                  }}
                  onClick={() => setPage(page - 1)}
                >
                  <p>Previous</p>
                </div>
              )}
              {page === totalPage ? (
                <div
                  style={{
                    padding: "5px",
                    textAlign: "center",
                    width: "110px",
                    backgroundColor: "lightgray",
                    color: "#fff",
                    borderRadius: "5px",
                    fontWeight: "600",
                  }}
                >
                  <p>Next</p>
                </div>
              ) : (
                <div
                  style={{
                    padding: "5px",
                    textAlign: "center",
                    width: "110px",
                    backgroundColor: "rgb(127 204 182 / 83%)",
                    color: "#000",
                    borderRadius: "5px",
                    fontWeight: "600",
                  }}
                  onClick={() => setPage(page + 1)}
                >
                  <p>Next</p>
                </div>
              )}
            </div>
          </>
        </div>
      </div> */}
    </div>
  );
}

export default CourseCategoryAdd;
