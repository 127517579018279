import React, { useEffect, useState } from "react";
import { Pie, Line } from "react-chartjs-2";
import styles from "./Dashboard.module.css";
// import faker from "faker";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { Link } from "react-router-dom";
ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title);

function Dashboard() {
  const [numbrlead, setNumberLead] = useState("");
  const [courselead, setCourseLead] = useState("");
  const data = {
    labels: ["Digital Marketing", "Advamce Excel", "Canva", "Tally"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5],
        backgroundColor: ["#e9c182", "#6E8658", "#AFCBD5", "#7fccb6"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 18,
          },
        },
      },
      title: {
        display: true,
        text: "Prospective Student Data",
        font: {
          size: 20,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          min: 0,
          max: 900,
          font: {
            size: 15,
          },
        },
      },
      y: {
        ticks: {
          stepSize: 100,
          min: 0,
          max: 700,
          font: {
            size: 15,
          },
        },
      },
    },
  };
  useEffect(() => {
    GetData();
    GetCourse();
  }, []);

  const GetData = () => {
    fetch("https://admin.digitalskillup.in/lead/alllead", { method: "GET" })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && data.length > 0) {
          setNumberLead(data?.length);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const GetCourse = () => {
    fetch("https://admin.digitalskillup.in/courses/allcourses", {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && data.length > 0) {
          setCourseLead(data?.length);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const FakeData = [
    {
      Month: "Jan",
      Lead: "100",
    },
    {
      Month: "Feb",
      Lead: "150",
    },
    {
      Month: "Mar",
      Lead: "130",
    },
    {
      Month: "Apr",
      Lead: "300",
    },
    {
      Month: "May",
      Lead: "400",
    },
    {
      Month: "Jun",
      Lead: "1000",
    },
    {
      Month: "Jul",
      Lead: "700",
    },
    {
      Month: "Aug",
      Lead: "800",
    },
    {
      Month: "Sep",
      Lead: "900",
    },
  ];

  const dataLine = {
    labels: FakeData.map((e) => e?.Month),
    datasets: [
      {
        label: "Lead",
        data: FakeData.map((e) => e?.Lead),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  return (
    <div>
      <div>
        <div className={styles.InnerTopContainer}>
          <div
            className={styles.Container}
            style={{ backgroundColor: "#F3E2C6" }}
          >
            <div className={styles.ButtonContainer}>
              <h2 className={styles.InnerContainerText}>Lead</h2>
              <Link
                to="/lead"
                className={styles.Button}
                style={{ backgroundColor: "#e9c182" }}
              >
                <p className={styles.ButtonText}>View Details</p>
              </Link>
            </div>
            <div
              className={styles.InnerContainerTextStudent}
              style={{ border: "10px solid #e9c182" }}
            >
              {numbrlead}
            </div>
          </div>
          <div
            className={styles.Container}
            style={{ backgroundColor: "#d1e561a1" }}
          >
            <div className={styles.ButtonContainer}>
              <h2 className={styles.InnerContainerText}>Total Student</h2>
              <div
                className={styles.Button}
                style={{ backgroundColor: "#c6df3d" }}
              >
                <p className={styles.ButtonText}>View Details</p>
              </div>
            </div>
            <div
              className={styles.InnerContainerTextStudent}
              style={{ border: "10px solid #c6df3d" }}
            >
              50
            </div>
          </div>
          <div
            className={styles.Container}
            style={{ backgroundColor: "#7ebeae52" }}
          >
            <div className={styles.ButtonContainer}>
              <h2 className={styles.InnerContainerText}>Courses</h2>
              <Link
                to="/course"
                className={styles.Button}
                style={{ backgroundColor: "#7fccb6" }}
              >
                <p className={styles.ButtonText}>View Details</p>
              </Link>
            </div>
            <div
              className={styles.InnerContainerTextStudent}
              style={{ border: "10px solid #7fccb6" }}
            >
              {courselead}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "80px",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "1300px",
          height: "70vh",
          // backgroundColor: "blue",
          flexWrap: "wrap",
        }}
      >
        <div style={{ height: "300px", width: "600px" }}>
          <Line options={options} data={dataLine} />
        </div>
        <div style={{ height: "270px", width: "270px" }}>
          <Pie data={data} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
