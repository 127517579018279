import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddBatch, AddSubscription } from "../../store/AdminReducer";
import ClipLoader from "react-spinners/ClipLoader";
import DatePicker from "react-datepicker";
import styx from "./c.css";

// import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// import { AddSubscription } from "../../store/AdminReducer";

function BatchAdd() {
  const [courseData, setCourseData] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [admin, setAdmin] = useState({
    name: "",
    duration: "",
    timing: "",
    shift: "",
    courseId: "",
  });
  const { _id } = useSelector((State) => State.admin.admin);

  const dispatch = useDispatch();
  const formHandel = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };
  // const { roles } = useSelector((state) => state.role);
  useEffect(() => {
    GetData();
    GetBatches();
  }, [page]);

  const GetData = () => {
    fetch("https://admin.digitalskillup.in/courses/allcourses", {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCourseData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const GetBatches = () => {
    fetch(`https://admin.digitalskillup.in/batch?page=${page}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setBatchData(data?.batch);
        setTotalPage(data?.totalPages);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // console.log("admin?.name", admin?.name);

  const addBatch = () => {
    console.log("admin?.name", admin?.name);
    console.log("admin?.courseId", admin?.courseId);
    console.log("startDate", startDate);
    console.log("admin?.duration", admin?.duration);
    if (!admin?.name) {
      alert("please fill in");
      return;
    }
    if (!admin?.courseId) {
      alert("please fill in");
      return;
    }
    if (!startDate) {
      alert("please fill in");
      return;
    }
    if (!admin?.duration) {
      alert("please fill in");
      return;
    }

    setLoading(true);
    const payload = {
      ...admin,
      timing: startDate,
    };
    fetch("https://admin.digitalskillup.in/batch", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.message == "Batch Successfully Created") {
          setLoading(false);
          alert("Batch Successfully Created");
          navigate("/batch");
        }
        console.log("response here00000-------", response);
      });
    // .finally(e => setLoading(false))
  };

  return (
    <div style={{ padding: "10px 2%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "rgb(228 160 54)",
        }}
      >
        <h2>Add Batch</h2>
        <Link
          to="/batch"
          style={{
            padding: "8px 15px",
            background: "rgb(228 160 54)",
            color: "#fff",
            fontWeight: "500",
            borderRadius: "3px",
            cursor: "pointer",
          }}
        >
          View Batchs
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          // border: "1px solid",
          marginTop: "15px",
          padding: "20px",
          gap: "5%",
          flexWrap: "wrap",
          rowGap: "15px",
          backgroundColor: "rgb(233 193 130 / 42%)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            minWidth: "300px",
            maxWidth: "500px",
            flex: "1",
          }}
        >
          <label
            htmlFor="title"
            style={{
              fontWeight: "500",
              fontSize: "20px",
              cursor: "pointer",
              color: "rgb(91, 89, 89)",
            }}
          >
            Name
          </label>
          <input
            onChange={formHandel}
            type="text"
            id="title"
            name="name"
            value={admin.name}
            style={{
              padding: "6px 12px",
              fontWeight: "400",
              fontSize: "16px",
              color: "rgb(91, 89, 89)",
            }}
          />
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            minWidth: "300px",
            maxWidth: "500px",
            flex: "1",
          }}
        >
          <label
            htmlFor="Details"
            style={{
              fontWeight: "500",
              fontSize: "20px",
              cursor: "pointer",
              color: "rgb(91, 89, 89)",
            }}
          >
            Details
          </label>
          <input
            onChange={formHandel}
            type="text"
            id="description"
            name="description"
            value={admin.description}
            style={{ padding: "6px 12px", fontWeight: "400", fontSize: "16px" }}
          />
        </div> */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            minWidth: "300px",
            maxWidth: "500px",
            flex: "1",
          }}
        >
          <label
            htmlFor="price"
            style={{
              fontWeight: "500",
              fontSize: "20px",
              cursor: "pointer",
              color: "rgb(91, 89, 89)",
            }}
          >
            Duration
          </label>
          <input
            onChange={formHandel}
            type="text"
            id="duration"
            name="duration"
            value={admin.duration}
            style={{
              padding: "6px 12px",
              fontWeight: "400",
              fontSize: "16px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            minWidth: "300px",
            maxWidth: "500px",
            flex: "1",
          }}
        >
          <label
            htmlFor="shift"
            style={{
              fontWeight: "500",
              fontSize: "20px",
              cursor: "pointer",
              color: "rgb(91, 89, 89)",
            }}
          >
            shift
          </label>
          <input
            onChange={formHandel}
            type="text"
            id="shift"
            name="shift"
            value={admin.shift}
            style={{
              padding: "6px 12px",
              fontWeight: "400",
              fontSize: "16px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            minWidth: "300px",
            maxWidth: "500px",
            flex: "1",
          }}
        >
          <label
            htmlFor="price"
            style={{
              fontWeight: "500",
              fontSize: "20px",
              cursor: "pointer",
              color: "rgb(91, 89, 89)",
            }}
          >
            Timing
          </label>
          {/* <input
            onChange={formHandel}
            type="text"
            id="timing"
            name="timing"
            value={admin.timing}
            style={{
              padding: "6px 12px",
              fontWeight: "400",
              fontSize: "16px",
            }}
          /> */}
          {/* <div
            style={{
              padding: "6px 12px",
              fontWeight: "400",
              fontSize: "16px",
              backgroundColor: "red",
            }}
          > */}
          <DatePicker
            // selected={moment(startDate).format("DD/MM/YYYY")}
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            // wrapperClassName="c1"
          />
          {/* </div> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            minWidth: "300px",
            maxWidth: "500px",
            flex: "1",
          }}
        >
          <label
            htmlFor="Course"
            style={{
              fontWeight: "500",
              fontSize: "20px",
              cursor: "pointer",
            }}
          >
            Course
          </label>
          <select
            name="courseId"
            onChange={formHandel}
            id="Course"
            value={admin.courseId}
            style={{
              padding: "6px 12px",
              fontWeight: "400",
              fontSize: "16px",
            }}
          >
            <option default>Select Course</option>
            {courseData.map((e) => (
              <option key={e._id} value={e._id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>
        <div
          // onClick={() =>
          //   dispatch(AddBatch({ ...admin, admin: _id })).then((e) => {
          //     setAdmin({
          //       name: "",
          //       description: "",
          //       duration: "",
          //       courseId: "",
          //       timing: "",
          //     });
          //   })
          // }
          onClick={addBatch}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            gap: "10px",
            maxWidth: "200px",
            flex: "1",
          }}
        >
          {loading ? (
            <div
              style={{
                padding: "6px 12px",
                cursor: "pointer",
              }}
            >
              <ClipLoader
                color={"rgb(228 160 54)"}
                loading={loading}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
                boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
                borderWidth: "3px",
                textAlign: "center",
                cursor: "pointer",
                background: "rgb(228 160 54)",
                color: "#fff",
              }}
            >
              Submit
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          marginTop: "30px",
          padding: "20px",
          width: "100%",
        }}
      >
        <div
          style={{
            marginTop: "50px",
            boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
            backgroundColor: "rgb(233 193 130 / 42%)",
            padding: "20px",
            width: "23%",
            width: "95%",
            maxWidth: "350px",
            margin: "auto",
          }}
        >
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              paddingBottom: "10px",
              // marginTop: "15px",
            }}
          >
            Active Batchs
          </h1>
          <>
            {batchData.map((e) => {
              return (
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "16px",
                    marginTop: "7px",
                  }}
                >
                  {e?.name}
                </p>
              );
            })}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                gap: "10px",
              }}
            >
              {page === 1 ? (
                <div
                  style={{
                    backgroundColor: "lightgray",
                    fontWeight: 600,
                    padding: "5px",
                    textAlign: "center",
                    width: "110px",
                    cursor: "default",
                    borderRadius: "5px",
                    color: "#fff",
                  }}
                >
                  <p>Previous</p>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "rgb(233 193 130 / 83%)",
                    fontWeight: 600,
                    padding: "5px",
                    textAlign: "center",
                    borderRadius: "5px",
                    width: "110px",
                    cursor: "default",
                  }}
                  onClick={() => setPage(page - 1)}
                >
                  <p>Previous</p>
                </div>
              )}
              {page === totalPage ? (
                <div
                  style={{
                    backgroundColor: "lightgray",
                    fontWeight: 600,
                    padding: "5px",
                    textAlign: "center",
                    width: "110px",
                    borderRadius: "5px",
                    cursor: "default",
                    color: "#fff",
                  }}
                >
                  <p>Next</p>
                </div>
              ) : (
                <div
                  style={{
                    backgroundColor: "rgb(233 193 130 / 83%)",
                    fontWeight: 600,
                    padding: "5px",
                    textAlign: "center",
                    borderRadius: "5px",
                    width: "110px",
                    cursor: "default",
                  }}
                  onClick={() => setPage(page + 1)}
                >
                  <p>Next</p>
                </div>
              )}
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default BatchAdd;
