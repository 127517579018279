import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { NavLink } from "react-router-dom";

function SubMenu({ isOpen, showLinkAnimation, rotes, setIsOpen }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const MenuAnimation = {
    hidden: {
      height: 0,
      opacity: 0,
      padding: 0,
      transition: {
        duration: 0.3,
        when: "afterChildren",
      },
    },
    show: {
      height: "auto",
      opacity: 1,
      transition: {
        duration: 0.2,
        when: "beforChildren",
      },
    },
  };
  const MenuItemAnimation = {
    hidden: (i) => ({
      padding: 0,
      x: "-100%",
      transition: {
        duration: (i + 1) * 0.1,
      },
    }),
    show: (i) => ({
      x: "0%",
      transition: {
        duration: (i + 1) * 0.1,
      },
    }),
  };
  useEffect(() => {
    if (!isOpen) {
      setIsMenuOpen(false);
    }
  }, [isOpen]);
  return (
    <>
      <div
        className="menu"
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
          setIsOpen(true);
        }}
      >
        <div className="menu-item">
          <div className="icon" style={{ color: "#000000c8" }}>
            {rotes.icon}{" "}
          </div>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                variants={showLinkAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="link_Text"
              >
                {rotes.name}{" "}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {isOpen && (
          <motion.div animate={isMenuOpen ? { rotate: -90 } : { rotate: 0 }}>
            <FaAngleDown style={{ color: "#000000c8" }} />
          </motion.div>
        )}
      </div>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            variants={MenuAnimation}
            initial="hidden"
            animate="show"
            exit="hidden"
            className="subrote"
          >
            {rotes.subRoutes.map((subRote, i) => (
              <React.Fragment key={i}>
                <motion.div
                  variants={MenuItemAnimation}
                  custom={i}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                >
                  <NavLink
                    activeclassname="active"
                    to={subRote.path}
                    className="Link"
                  >
                    <div className="icon" style={{ color: "#000000c8" }}>
                      {subRote.icon}{" "}
                    </div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showLinkAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_Text"
                        >
                          {subRote.name}{" "}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                </motion.div>
              </React.Fragment>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default SubMenu;
