import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaEdit } from "react-icons/fa";
import moment from "moment";
// import { GetRole } from "../../store/RoleReducer";
import { MdDelete } from "react-icons/md";
import {
  GetSubscription,
  updateSubscription,
  DeleteAdmin,
  DeleteSubscription,
} from "../../store/AdminReducer";
import CourseModal from "./CourseModal";
import BatchModel from "./BatchModel";
import ClipLoader from "react-spinners/ClipLoader";

function UserView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { subscriptions } = useSelector((State) => State.admin);
  const [batchDetail, setBatchDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();

  // useEffect(() => {
  //   dispatch(GetSubscription());
  // }, [dispatch]);

  useEffect(() => {
    GetData();
  }, [page]);

  const GetData = () => {
    setLoading(true);
    fetch(`https://admin.digitalskillup.in/batch?page=${page}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log("data", data);
        // if (data?._id) {
        setBatchDetails(data?.batch);
        setTotalPage(data?.totalPages);
        setLoading(false);
        // }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <div style={{ padding: "10px 2%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "rgb(228 160 54)",
            cursor: "default",
          }}
        >
          <h2>Batch</h2>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <div
              style={{
                padding: "8px 15px",
                background: "black",
                color: "white",
                fontWeight: "500",
                borderRadius: "3px",
                cursor: "pointer",
              }}
            >
              Page-{page}
            </div>
            <div
              onClick={() => navigate("/batch/add")}
              style={{
                padding: "8px 15px",
                background: "#e9c182",
                color: "rgb(91, 89, 89)",
                fontWeight: "500",
                borderRadius: "3px",
                cursor: "pointer",
              }}
            >
              Add New Batch
            </div>
          </div>
        </div>
        <div style={{ overflow: "auto" }}>
          <table
            style={{ width: "100%", marginTop: "20px", cursor: "default" }}
          >
            <thead
              style={{
                backgroundColor: "rgb(233 193 130 / 54%)",
                color: "rgb(91, 89, 89)",
              }}
            >
              <tr>
                <th style={{ padding: "10px", width: "70px" }}>NO.</th>
                <th style={{ padding: "10px" }}>Name</th>
                <th style={{ padding: "10px" }}>Course</th>
                <th style={{ padding: "10px" }}>Duration</th>
                <th style={{ padding: "10px" }}>shift</th>
                <th style={{ padding: "10px" }}>time</th>
                <th style={{ padding: "10px" }}>Delete</th>
                <th style={{ padding: "10px" }}>Edit</th>
              </tr>
            </thead>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  left: "55%",
                }}
              >
                <ClipLoader
                  color={"#FFC1CC"}
                  loading={loading}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <tbody style={{ color: "white" }}>
                {batchDetail?.map((e, i) => (
                  <TableDate
                    e={e}
                    i={i}
                    setBatchDetails={setBatchDetails}
                    loading={loading}
                    GetData={GetData}
                    page={page}
                  />
                ))}
              </tbody>
            )}
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            gap: "10px",
          }}
        >
          {page === 1 ? (
            <div
              style={{
                backgroundColor: "lightgray",
                fontWeight: 600,
                padding: "5px",
                textAlign: "center",
                width: "110px",
                cursor: "default",
                borderRadius: "5px",
                color: "#fff",
              }}
            >
              <p>Previous</p>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: "rgb(233 193 130 / 83%)",
                fontWeight: 600,
                padding: "5px",
                textAlign: "center",
                borderRadius: "5px",
                width: "110px",
                cursor: "default",
              }}
              onClick={() => setPage(page - 1)}
            >
              <p>Previous</p>
            </div>
          )}
          {page === totalPage ? (
            <div
              style={{
                backgroundColor: "lightgray",
                fontWeight: 600,
                padding: "5px",
                textAlign: "center",
                width: "110px",
                borderRadius: "5px",
                cursor: "default",
                color: "#fff",
              }}
            >
              <p>Next</p>
            </div>
          ) : (
            <div
              style={{
                backgroundColor: "rgb(233 193 130 / 83%)",
                fontWeight: 600,
                padding: "5px",
                textAlign: "center",
                borderRadius: "5px",
                width: "110px",
                cursor: "default",
              }}
              onClick={() => setPage(page + 1)}
            >
              <p>Next</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
const TableDate = ({ e, i, setBatchDetails, GetData, page }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(e.name);
  const [description, setDescription] = useState(e.description);
  const [course, setCourse] = useState(e?.courseID?.name);
  const [duration, setDuration] = useState(e.duration);
  const [open, setOpen] = useState(false);
  const [shift, setShift] = useState(e.shift);
  const [timing, setTiming] = useState(e.timing);
  const [modal, setModal] = useState(false);
  console.log(
    "moment(timing).subtract(10",
    moment(timing).subtract(10, "days").calendar()
  );

  const closeModel = () => {
    setModal(false);
  };

  useEffect(() => {
    setName(e.name);
    setCourse(e?.courseID?.name);
    setDuration(e.duration);
    setTiming(e.timing);
  }, [e]);

  const DeleteBatch = () => {
    fetch(`https://admin.digitalskillup.in/batch/${e._id}?page=${page}`, {
      method: "DELETE",
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setBatchDetails([...response.allBatchs]);
        GetData();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      {modal && (
        <BatchModel
          closeModel={closeModel}
          GetDatafromBatch={GetData}
          id={e?._id}
        />
      )}
      {open && (
        <div
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            background: "#000000b8",
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "25%",
              height: "20%",
              backgroundColor: "white",
              marginTop: "15px",
              padding: "20px",
              //   gap: "15%",
              flexWrap: "wrap",
              rowGap: "15px",
            }}
          >
            <h1
              style={{
                fontSize: "20px",
                fontWeight: "500",
                textAlign: "center",
                color: "black",
                marginBottom: "10px",
              }}
            >
              Are you sure?
            </h1>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "30px",
              }}
            >
              <div
                style={{
                  background: "gray",
                  padding: "5px 10px",
                  borderRadius: "7px",
                }}
                onClick={DeleteBatch}
              >
                <p>yes</p>
              </div>
              <div
                style={{
                  background: "gray",
                  padding: "5px 10px",
                  borderRadius: "7px",
                }}
                onClick={() => setOpen(false)}
              >
                <p>No</p>
              </div>
            </div>
            <div
              onClick={() => setOpen(false)}
              style={{
                padding: "2px 5px",
                background: "rgb(228 160 54)",
                color: "#fff",
                fontWeight: "500",
                borderRadius: "3px",
                cursor: "pointer",
                position: "absolute",
                top: "42%",
                left: "59%",
                width: "3%",
              }}
            >
              close
            </div>
          </div>
        </div>
      )}
      <tr>
        <td
          style={{
            backgroundColor: "rgb(243 226 198 / 52%)",
            padding: "10px",
            textAlign: "center",
            width: "70px",
            color: "rgb(91, 89, 89)",
            cursor: "default",
          }}
        >
          {i + 1}
        </td>
        <td
          style={{
            backgroundColor: "rgb(243 226 198 / 52%)",
            padding: "10px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <input
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "18px",
              color: "rgb(91, 89, 89)",
              cursor: "default",
            }}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </td>
        <td
          style={{
            backgroundColor: "rgb(243 226 198 / 52%)",
            padding: "10px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <input
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "18px",
              color: "rgb(91, 89, 89)",
              cursor: "default",
            }}
            type="text"
            value={course}
            onChange={(e) => setCourse(e.target.value)}
          />
        </td>
        <td
          style={{
            backgroundColor: "rgb(243 226 198 / 52%)",
            padding: "10px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <input
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "18px",
              color: "rgb(91, 89, 89)",
              cursor: "default",
            }}
            // type="number"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          />
        </td>
        <td
          style={{
            backgroundColor: "rgb(243 226 198 / 52%)",
            padding: "10px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <input
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "18px",
              color: "rgb(91, 89, 89)",
              cursor: "default",
            }}
            // type="number"
            value={shift}
            onChange={(e) => setDuration(e.target.value)}
          />
        </td>
        <td
          style={{
            backgroundColor: "rgb(243 226 198 / 52%)",
            padding: "10px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <input
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "18px",
              color: "rgb(91, 89, 89)",
              cursor: "default",
            }}
            // type="number"
            // value={moment(timing).subtract(10, "days").calendar()}
            // 2024-03-31T08:16:43.000Z
            // value={moment(timing).subtract(10, "days").calendar()}
            value={moment(timing).format("MM/DD/YYYY")}
            onChange={(e) => setTiming(e.target.value)}
          />
        </td>
        <td
          style={{
            backgroundColor: "rgb(243 226 198 / 52%)",
            padding: "10px",
            textAlign: "center",
            width: "100px",
          }}
        >
          <MdDelete
            // onClick={() => {
            //   dispatch(DeleteSubscription({ id: e._id })).then((e) =>
            //     console.log("e...........", e)
            //   );
            // }}
            onClick={() => setOpen(true)}
            size={25}
            style={{ cursor: "pointer", color: "rgb(231 163 56 / 73%)" }}
          />
        </td>
        <td
          style={{
            backgroundColor: "rgb(243 226 198 / 52%)",
            padding: "10px",
            textAlign: "center",
            width: "70px",
          }}
        >
          <FaEdit
            onClick={() => setModal(true)}
            style={{
              color: "rgb(231 163 56 / 73%)",
              cursor: "pointer",
            }}
          />
        </td>
      </tr>
    </>
  );
};
export default UserView;

// const LeadArray = [
//   {
//     name: "Canva Creative Pro Batch",
//     details: "Comprehensive training program covering advanced digital",
//     price: "10000",
//   },
//   {
//     name: "Digital Marketing Mastery Batch",
//     details: "In-depth course designed for individuals seeking expertise",
//     price: "7000",
//   },
//   {
//     name: "Canva",
//     details: "Advanced-level course designed for experienced",
//     price: "1200",
//   },
//   {
//     name: "Tally Ninja Batch",
//     details: " Advanced course for marketing professionals looking to ",
//     price: "15000",
//   },
// ];
