import React, { useState } from "react";
import styles from "./Changepass.module.css";
import { IoLockClosedSharp, IoLockOpenSharp } from "react-icons/io5";
import axios from "axios";
import { useSelector } from "react-redux";

const Changepass = () => {
  const { AdminEmail } = useSelector((select) => select?.user);
  // console.log("AdminEmail", AdminEmail);
  const [icon1, setIcon1] = useState(false);
  const [icon2, setIcon2] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reenternewpassword, setreenternewpassword] = useState("");
  // const [password, setPassword] = useState({
  //   oldPassword: "",
  //   newPassword: "",
  //   reenterNewPassword: "",
  // });

  // const handlePassword = (e) => {
  //   setPassword({ ...password, [e.target.name]: e.target.value });
  // };

  const ChangePassword = () => {
    const payload = {
      email: AdminEmail,
      oldPassword: oldPassword,
      newPassword: newPassword,
      reenterNewPassword: reenternewpassword,
    };
    console.log("payload here+++++++", payload);
    // if (newPassword !== reenternewpassword) {
    //   alert("Both new Password and reEnter password should be Same");
    //   return;
    // }
    axios
      .post("https://admin.digitalskillup.in/changepassword", payload)
      .then((response) => {
        console.log("response of change pass", response);
        if (response?.data?.message == "Password changed successfully") {
          alert("Password changed succesfully");
          setOldPassword("");
          setNewPassword("");
          setreenternewpassword("");
        }
        if (response?.data?.message == "Invalid old password")
          alert("Old password should be same");
      })
      .catch((error) => {
        alert("Failed to change password");
        console.error("Error:", error);
      });
  };

  return (
    <div className={styles.ChangePassMainDiv}>
      {/* <img src="./Images/ChangePass.jpg" className={styles.ChangepassImage} /> */}
      <div className={styles.ChangePassDiv}>
        <h3 className={styles.ResetPasswordText}>Reset Password</h3>
        <div className={styles.OldPassContainer}>
          <input
            placeholder="Old Password"
            id="oldPassword"
            name="oldPassword"
            className={styles.inputBox}
            type={icon1 === true ? "text" : "password"}
            onChange={(e) => setOldPassword(e.target.value)}
            value={oldPassword}
          />
          <button
            onClick={() => setIcon1(!icon1)}
            className={styles.lockunlockIcon}
          >
            {icon1 === true ? <IoLockOpenSharp /> : <IoLockClosedSharp />}
          </button>
        </div>
        <p className={styles.ForgotPassText}>Forgot password?</p>
        <div className={styles.OldPassContainer}>
          <input
            placeholder="New Password"
            className={styles.inputBox}
            id="newPassword"
            name="newPassword"
            type={icon2 === true ? "text" : "password"}
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
          />
          <button
            onClick={() => setIcon2(!icon2)}
            className={styles.lockunlockIcon}
          >
            {icon2 === true ? <IoLockOpenSharp /> : <IoLockClosedSharp />}
          </button>
          {/* <CiLock /> */}
        </div>
        <div className={styles.OldPassContainer} style={{ height: "50px" }}>
          <input
            placeholder="Confirm New Password"
            className={styles.inputBox}
            id="reenterNewPassword"
            name="reenterNewPassword"
            // type={icon1 === true ? "text" : "password"}
            onChange={(e) => setreenternewpassword(e.target.value)}
            value={reenternewpassword}
          />
          {/* <CiLock /> */}
        </div>
        <div className={styles.ButtonContainer} onClick={ChangePassword}>
          <p>Set Password</p>
        </div>
      </div>
    </div>
  );
};

export default Changepass;
