import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import AdminReducer from './store/AdminReducer';
import RoleReducer from './store/RoleReducer';
import CategoryReducer from './store/CategoryReducer';
import CollagesReducer from './store/CollagesReducer';
import BlogReducers from './store/BlogReducers';
import ExamReducers from './store/ExamReducers';
import userReducers from './store/userReducers';
import cmsReducers from './store/cmsReducers';
const store = configureStore({
  reducer: {
    admin: AdminReducer,
    role: RoleReducer,
    user: userReducers,
    category: CategoryReducer,
    listing: CollagesReducer,
    blog: BlogReducers,
    exam: ExamReducers,
    cms: cmsReducers
  }
})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
