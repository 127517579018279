import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FaHome, FaBars, FaUserShield } from "react-icons/fa";
import { MdExitToApp } from "react-icons/md";
import { AiFillDashboard, AiFillSetting } from "react-icons/ai";
import { ImBooks, ImClock } from "react-icons/im";
import { FiLayers } from "react-icons/fi";
import { BsBuildingAdd, BsAwardFill } from "react-icons/bs";
import "./Sidebar.css";
import { NavLink } from "react-router-dom";
import SubMenu from "./SubMenu";
import { useDispatch, useSelector } from "react-redux";
import { TokenLogin, adminLogin } from "../../../store/AdminReducer";
import { RouteAllowed } from "../../../Functions";
import { GetRole } from "../../../store/RoleReducer";
import { RiFileAddFill } from "react-icons/ri";
import { GiNotebook } from "react-icons/gi";
import { RxDashboard } from "react-icons/rx";
import { MdHotelClass } from "react-icons/md";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineLogout } from "react-icons/ai";
import { RiLockPasswordFill } from "react-icons/ri";
import { GiBookStorm } from "react-icons/gi";
import { FaQuestionCircle } from "react-icons/fa";
import axios from "axios";
import { AddAdminEmail } from "../../../store/userReducers";
import { IoLockClosedSharp, IoLockOpenSharp } from "react-icons/io5";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    permission: "dashboard",
    icon: (
      <RxDashboard size={22} style={{ marginTop: "5px", marginRight: "7px" }} />
    ),
  },
  //   {
  //     path: "/role",
  //     name: "Role",
  //     permission: "roll",
  //     icon: <BsAwardFill size={25} />,
  //   },
  {
    name: "Categories",
    path: "/category",
    icon: (
      <GiBookStorm size={22} style={{ marginTop: "5px", marginRight: "7px" }} />
    ),
  },
  {
    name: "Courses",
    path: "/course",
    icon: (
      <GiNotebook size={22} style={{ marginTop: "5px", marginRight: "7px" }} />
    ),
  },
  {
    name: "Lead",
    path: "/lead",
    permission: "blog create",
    icon: (
      <RiFileAddFill
        size={22}
        style={{ marginTop: "5px", marginRight: "7px" }}
      />
    ),
  },
  {
    name: "ContactUs Lead",
    path: "/contactUs",
    permission: "blog create",
    icon: (
      <RiFileAddFill
        size={22}
        style={{ marginTop: "5px", marginRight: "7px" }}
      />
    ),
  },
  {
    name: "Enrolled Teacher",
    path: "/EnrolledTeacher",
    icon: (
      <RiFileAddFill
        size={22}
        style={{ marginTop: "5px", marginRight: "7px" }}
      />
    ),
  },
  {
    name: "Batch",
    path: "/batch",
    icon: (
      <MdHotelClass
        size={22}
        style={{ marginTop: "5px", marginRight: "7px" }}
      />
    ),
  },
  {
    name: "FAQ",
    path: "/courseFAQ",
    icon: (
      <FaQuestionCircle
        size={22}
        style={{ marginTop: "5px", marginRight: "7px" }}
      />
    ),
  },
  {
    name: "Blogs",
    path: "/Blogs",
    icon: (
      <FaQuestionCircle
        size={22}
        style={{ marginTop: "5px", marginRight: "7px" }}
      />
    ),
  },
  {
    name: "Change Password",
    path: "/changepassword",
    permission: "blog create",
    icon: (
      <RiLockPasswordFill
        size={22}
        style={{ marginTop: "5px", marginRight: "7px" }}
      />
    ),
  },
];
function Sidebar({ children, title, rightComp }) {
  const showLinkAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "auto",
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const [isOpen, setIsOpen] = useState(true);
  const [icon, setIcon] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { adminAuth } = useSelector((State) => State.admin);
  const [auth, setAuth] = useState(false);
  const { roles } = useSelector((state) => state.role);
  const { adminUser } = useSelector((state) => state.admin);
  useEffect(() => {
    const token = async () => {
      const token = await localStorage.getItem("Token");
      if (token) {
        dispatch(TokenLogin({ token })).then(
          (e) => e?.payload?.success && setAuth(true)
        );
      }
    };
    token();
  }, []);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 550) {
        setIsOpen(false);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Signin = () => {
    if (!email) {
      alert("please fill in");
      return;
    }
    if (!password) {
      alert("please fill in");
      return;
    }
    const userData = {
      email: email,
      password: password,
    };

    axios
      .post("https://admin.digitalskillup.in/AdminLogin", userData)
      .then((response) => {
        console.log("response?.user?.email", response?.data?.user?.email);
        alert("Login Successfull!");
        setAuth(true);
        dispatch(AddAdminEmail(response?.data?.user?.email));
        setEmail("");
        setPassword("");
      })
      .catch((error) => {
        alert("Failed to Login. Please try again.");
        console.error("Error:", error);
      });
  };
  return (
    <>
      {auth ? (
        <>
          <div
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              overflow: "hidden",
            }}
          >
            <motion.div
              animate={{
                minWidth: isOpen ? "270px" : "65px",
                transition: {
                  duration: 0.5,
                  type: "spring",
                  damping: 15,
                },
              }}
              className="SideBar"
            >
              <div className="logo">
                {isOpen && (
                  <img
                    src="../Images/FinalLogo.png"
                    style={{ height: "50px", width: "120px" }}
                  />
                )}
                <div onClick={() => setIsOpen(!isOpen)}>
                  <BiMenuAltRight size={40} />
                </div>
              </div>
              <section className="routes">
                {routes.map((rotes, i) => (
                  <React.Fragment key={i}>
                    <>
                      <NavLink
                        activeclassname="active"
                        to={rotes.path}
                        key={rotes.name}
                        className="Link"
                      >
                        <div className="icon" style={{ color: "#000000c8" }}>
                          {rotes.icon}{" "}
                        </div>
                        <AnimatePresence>
                          {isOpen && (
                            <motion.div
                              variants={showLinkAnimation}
                              initial="hidden"
                              animate="show"
                              exit="hidden"
                              className="link_Text"
                            >
                              {rotes.name}{" "}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </NavLink>
                    </>
                  </React.Fragment>
                ))}
              </section>
              <div
                className="logout"
                style={{ marginBottom: "20px" }}
                onClick={() => setAuth(false)}
              >
                {isOpen && (
                  <h4
                    style={{
                      fontSize: "27px",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  >
                    Log out
                  </h4>
                )}
                <div>
                  <AiOutlineLogout
                    size={30}
                    style={{ marginTop: "7px", cursor: "pointer" }}
                  />
                </div>
              </div>
            </motion.div>
            <main style={{ width: "100%", overflow: "auto" }}>{children}</main>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgb(237 222 225 / 88%)",
            }}
          >
            <div
              style={{
                backgroundColor: "rgb(233 201 207)",
                display: "flex",
                flexDirection: "column",
                height: "auto",
                width: "400px",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  color: "black",
                  opacity: "1",
                  width: "100%",
                  textAlign: "center",
                  padding: "15px 20px ",
                  fontWeight: "500",
                  fontSize: "20px",
                  backgroundColor: "rgb(233 201 207)",
                }}
              >
                Admin Panel
              </div>
              <div style={{ textAlign: "center" }}>
                <img
                  src="../Images/DigitalSkillup.png"
                  style={{ height: "70px", width: "70px" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                  gap: "10px",
                }}
              >
                <label
                  style={{ color: "black", fontSize: "20px" }}
                  htmlFor="name"
                >
                  Email
                </label>
                <input
                  type="text"
                  placeholder="Email"
                  id="name"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    border: "1px solid gray",
                    outline: "none",
                    padding: "8px 12px",
                    fontSize: "15px ",
                    borderRadius: "5px",
                  }}
                />
                <label
                  style={{ color: "black", fontSize: "20px" }}
                  htmlFor="pass"
                >
                  {" "}
                  Password
                </label>
                <input
                  placeholder="Password"
                  id="pass"
                  name="password"
                  value={password}
                  type={icon === true ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    border: "1px solid gray",
                    outline: "none",
                    padding: "8px 12px",
                    fontSize: "15px ",
                    borderRadius: "5px",
                  }}
                />
                <button
                  onClick={() => setIcon(!icon)}
                  // className={styles.lockunlockIcon}
                  style={{
                    position: "relative",
                    height: "20px",
                    width: "20px",
                    border: "none",
                    bottom: "38px",
                    left: "90%",
                  }}
                >
                  {icon === true ? <IoLockOpenSharp /> : <IoLockClosedSharp />}
                </button>
              </div>
              <div
                // onClick={(e) => {
                //   e.preventDefault();
                //   dispatch(adminLogin({ email, password }))
                //     .then((e) => {
                //       setAuth(e?.payload?.success);
                //     })
                //     .catch((e) => alert(e.payload.message));
                // }}

                onClick={Signin}
                style={{
                  fontWeight: "500",
                  margin: "0px 20px 20px 20px",
                  padding: "8px",
                  cursor: "pointer",
                  borderRadius: "5px",
                  backgroundColor: "rgb(241 181 198)",
                  width: "100px",
                  border: "1px solid rgb(241 134 164)",
                  textAlign: "center",
                }}
              >
                Submit
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default Sidebar;
