import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import DatePicker from "react-datepicker";
import styx from "./c.css";

// import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// import { AddSubscription } from "../../store/AdminReducer";

function AddCourseFaq() {
  const [courseData, setCourseData] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [admin, setAdmin] = useState({
    Question: "",
    Answer: "",
  });
  const { _id } = useSelector((State) => State.admin.admin);

  const dispatch = useDispatch();
  const formHandel = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const addFAQ = () => {
    if (!admin?.Question) {
      alert("please fill in");
      return;
    }
    if (!admin?.Answer) {
      alert("please fill in");
      return;
    }
    setLoading(true);
    const payload = {
      ...admin,
    };
    fetch("https://admin.digitalskillup.in/CourseFaq", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.message == "FAQ Successfully Created") {
          setLoading(false);
          alert("FAQ Successfully Created");
          navigate("/courseFAQ");
        }
      });
    // .finally(e => setLoading(false))
  };

  return (
    <div style={{ padding: "10px 2%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "rgb(228 160 54)",
        }}
      >
        <h2>Add FAQ</h2>
        <Link
          to="/courseFAQ"
          style={{
            padding: "8px 15px",
            background: "rgb(228 160 54)",
            color: "#fff",
            fontWeight: "500",
            borderRadius: "3px",
            cursor: "pointer",
          }}
        >
          View FAQ
        </Link>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          // border: "1px solid",
          marginTop: "15px",
          padding: "20px",
          gap: "5%",
          flexWrap: "wrap",
          rowGap: "15px",
          backgroundColor: "rgb(233 193 130 / 42%)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            minWidth: "300px",
            maxWidth: "1000px",
            flex: "1",
          }}
        >
          <label
            htmlFor="Question"
            style={{
              fontWeight: "500",
              fontSize: "20px",
              cursor: "pointer",
              color: "rgb(91, 89, 89)",
            }}
          >
            Question
          </label>
          <input
            onChange={formHandel}
            type="text"
            id="title"
            name="Question"
            value={admin.Question}
            style={{
              padding: "6px 12px",
              fontWeight: "400",
              fontSize: "16px",
              color: "rgb(91, 89, 89)",
            }}
          />
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            minWidth: "300px",
            maxWidth: "500px",
            flex: "1",
          }}
        >
          <label
            htmlFor="Details"
            style={{
              fontWeight: "500",
              fontSize: "20px",
              cursor: "pointer",
              color: "rgb(91, 89, 89)",
            }}
          >
            Details
          </label>
          <input
            onChange={formHandel}
            type="text"
            id="description"
            name="description"
            value={admin.description}
            style={{ padding: "6px 12px", fontWeight: "400", fontSize: "16px" }}
          />
        </div> */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            minWidth: "300px",
            maxWidth: "1000px",
            flex: "1",
          }}
        >
          <label
            htmlFor="Answer"
            style={{
              fontWeight: "500",
              fontSize: "20px",
              cursor: "pointer",
              color: "rgb(91, 89, 89)",
            }}
          >
            Answer
          </label>
          <input
            onChange={formHandel}
            type="text"
            id="Answer"
            name="Answer"
            value={admin.Answer}
            style={{
              padding: "6px 12px",
              fontWeight: "400",
              fontSize: "16px",
            }}
          />
        </div>
        <div
          onClick={addFAQ}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            gap: "10px",
            maxWidth: "200px",
            flex: "1",
          }}
        >
          {loading ? (
            <div
              style={{
                padding: "6px 12px",
                cursor: "pointer",
              }}
            >
              <ClipLoader
                color={"rgb(228 160 54)"}
                loading={loading}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
                boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
                borderWidth: "3px",
                textAlign: "center",
                cursor: "pointer",
                background: "rgb(228 160 54)",
                color: "#fff",
              }}
            >
              Submit
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddCourseFaq;
