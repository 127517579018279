import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetRole } from "../../store/RoleReducer";
import { MdEdit } from "react-icons/md";
import {
  DeleteAdmin,
  DeleteSubscription,
  GetSubscription,
  updateCourse,
  updateSubscription,
} from "../../store/AdminReducer";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import SubscriptionAdd from "./SubscriptionAdd";
import CourseModal from "./CourseModal";
import ClipLoader from "react-spinners/ClipLoader";
import BlogModel from "./BlogModel";

function Blogs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { admin, subscriptions } = useSelector((State) => State.admin);
  const { admin } = useSelector((State) => State.admin);
  const [blogsData, setBlogsData] = useState([]);
  const [loading, setLoading] = useState(false);
  //   {
  //     name: "Digital Marketing",
  //     details: "It will take 3 months",
  //     price: "8986",
  //   },
  //   {
  //     name: "Advance Excel",
  //     details: "It will take 3 months",
  //     price: "6754",
  //   },
  //   {
  //     name: "Canva",
  //     details: "It will take 3 months",
  //     price: "9876",
  //   },
  //   {
  //     name: "Tally",
  //     details: "It will take 3 months",
  //     price: "8976",
  //   },
  // ];

  useEffect(() => {
    GetData();
  }, []);

  const GetData = () => {
    setLoading(true);
    fetch("https://admin.digitalskillup.in/blogs", { method: "GET" })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setBlogsData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // useEffect(() => {
  //   dispatch(GetSubscription({ admin: admin._id }));
  // }, [dispatch, admin._id]);
  return (
    <>
      <div style={{ padding: "10px 2%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "rgb(88 155 136)",
            cursor: "auto",
          }}
        >
          <h2>Blogs</h2>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <div
              onClick={() => navigate("/BlogAdd")}
              style={{
                padding: "8px 15px",
                background: "#7fccb6",
                color: "rgb(91, 89, 89)",
                fontWeight: "500",
                borderRadius: "3px",
                cursor: "pointer",
              }}
            >
              Add New Blog
            </div>
          </div>
        </div>
        <div style={{ overflow: "auto" }}>
          <table
            style={{ width: "100%", marginTop: "20px", cursor: "default" }}
          >
            <thead
              style={{
                backgroundColor: "rgb(127 204 182 / 45%)",
                color: "rgb(91, 89, 89)",
              }}
            >
              <tr>
                <th style={{ padding: "10px", width: "70px" }}>NO.</th>
                <th style={{ padding: "10px" }}>Name</th>
                <th style={{ padding: "10px" }}>Description</th>
                <th style={{ padding: "10px" }}>Delete</th>
                <th style={{ padding: "10px" }}>Edit</th>
              </tr>
            </thead>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  left: "55%",
                }}
              >
                <ClipLoader
                  color={"#FFC1CC"}
                  loading={loading}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <tbody style={{ color: "white" }}>
                {blogsData?.map((e, i) => (
                  <TableDate
                    e={e}
                    i={i}
                    GetData={GetData}
                    setBlogsData={setBlogsData}
                    blogsData={blogsData}
                  />
                ))}
              </tbody>
            )}
          </table>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "30px",
            gap: "10px",
            cursor: "default",
          }}
        >
          {page === 1 ? (
            <div
              style={{
                padding: "5px",
                textAlign: "center",
                width: "110px",
                backgroundColor: "lightgray",
                color: "#fff",
                // fontFamily: "poppins",
                borderRadius: "5px",
                fontWeight: "600",
              }}
            >
              <p>Previous</p>
            </div>
          ) : (
            <div
              style={{
                padding: "5px",
                textAlign: "center",
                width: "110px",
                backgroundColor: "rgb(127 204 182 / 83%)",
                color: "#000",
                borderRadius: "5px",
                fontWeight: "600",
              }}
              onClick={() => setPage(page - 1)}
            >
              <p>Previous</p>
            </div>
          )}
          {page === totalPage ? (
            <div
              style={{
                padding: "5px",
                textAlign: "center",
                width: "110px",
                backgroundColor: "lightgray",
                color: "#fff",
                borderRadius: "5px",
                fontWeight: "600",
              }}
            >
              <p>Next</p>
            </div>
          ) : (
            <div
              style={{
                padding: "5px",
                textAlign: "center",
                width: "110px",
                backgroundColor: "rgb(127 204 182 / 83%)",
                color: "#000",
                borderRadius: "5px",
                fontWeight: "600",
              }}
              onClick={() => setPage(page + 1)}
            >
              <p>Next</p>
            </div>
          )}
        </div> */}
      </div>
    </>
  );
}
const TableDate = ({ e, i, setBlogsData, GetData }) => {
  const [name, setName] = useState(e.name);
  const [description, setDescription] = useState(e.description);
  const [modal, setModal] = useState(false);

  const closeModel = () => {
    setModal(false);
  };
  useEffect(() => {
    setName(e.name);
    setDescription(e.description);
    // setPrice(e.price);
  }, [e]);
  const DeleteBlog = () => {
    fetch(`https://admin.digitalskillup.in/blogs/${e._id}`, {
      method: "DELETE",
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log("hello", response);
        setBlogsData([...response.blogs]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      {modal && (
        <BlogModel
          closeModel={closeModel}
          id={e._id}
          GetDatafromBlog={GetData}
        />
      )}
      <tr>
        <td
          style={{
            backgroundColor: "rgb(126 190 174 / 18%)",
            padding: "7px",
            textAlign: "center",
            width: "70px",
            color: "rgb(91, 89, 89)",
            cursor: "default",
          }}
        >
          {i + 1}
        </td>
        <td
          style={{
            backgroundColor: "rgb(126 190 174 / 18%)",
            padding: "7px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <input
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "16px",
              width: "180px",
              color: "rgb(91, 89, 89)",
              cursor: "default",
            }}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </td>
        <td
          style={{
            backgroundColor: "rgb(126 190 174 / 18%)",
            padding: "7px",
            textAlign: "center",
            width: "30%",
          }}
        >
          <input
            style={{
              textAlign: "center",
              border: "none",
              outline: "none",
              background: "none",
              boxShadow: "none",
              fontSize: "16px",
              width: "180px",
              color: "rgb(91, 89, 89)",
              cursor: "default",
            }}
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </td>
        <td
          style={{
            backgroundColor: "rgb(126 190 174 / 18%)",
            padding: "7px",
            textAlign: "center",
            width: "100px",
          }}
        >
          <MdDelete
            onClick={DeleteBlog}
            size={25}
            style={{ cursor: "pointer", color: "#7fccb6" }}
          />
        </td>

        <td
          style={{
            backgroundColor: "rgb(126 190 174 / 18%)",
            padding: "7px",
            textAlign: "center",
            width: "70px",
          }}
        >
          <button
            style={{
              backgroundColor: "#FFF",
              color: "rgb(91, 89, 89)",
              padding: "6px 10px",
              border: "1px solid #7fccb6",
              outline: "none",
              borderRadius: "3px",
              marginBottom: "3px",
              cursor: "pointer",
            }}
            onClick={() => setModal(true)}
          >
            <MdEdit size={17} style={{ cursor: "pointer", color: "#7fccb6" }} />
          </button>
        </td>
      </tr>
    </>
  );
};
export default Blogs;
