import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios'

export const AddCollage = createAsyncThunk(
    'AddCollage',
    async (body) => {
        try {
            const response = await axios.post(`http://localhost:8000/api/v1/collages`, body)
            return response.data

        } catch (err) {
            return err.response.data
        }

    }
)
export const GetCollages = createAsyncThunk(
    'GetCollages',
    async () => {
        try {
            const response = await axios.get(`http://localhost:8000/api/v1/collages`)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const GetCollage = createAsyncThunk(
    'GetCollage',
    async ({ id }) => {
        try {
            const response = await axios.get(`http://localhost:8000/api/v1/collage/${id}`)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const UpdateCollage = createAsyncThunk(
    'UpdateCollage',
    async (body) => {
        try {
            const response = await axios.put(`http://localhost:8000/api/v1/collages`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const DeleteCollages = createAsyncThunk(
    'DeleteCollages',
    async ({ id }) => {
        try {
            const response = await axios.delete(`http://localhost:8000/api/v1/collages?id=${id}`,)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const CollageApproveAndDisapprove = createAsyncThunk(
    'CollageApproveAndDisapprove',
    async ({ id, approval }) => {
        try {
            const response = await axios.put(`http://localhost:8000/api/v1/collage/${id}`, { approval })
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const AddCourse = createAsyncThunk(
    'AddCourse',
    async (body) => {
        try {
            const response = await axios.post(`http://localhost:8000/api/v1/course`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)






export const AddSchool = createAsyncThunk(
    'AddSchool',
    async (body) => {
        try {
            const response = await axios.post(`http://localhost:8000/api/v1/schools`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const GetSchools = createAsyncThunk(
    'GetSchools',
    async () => {
        try {
            const response = await axios.get(`http://localhost:8000/api/v1/schools`)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const GetSchool = createAsyncThunk(
    'GetSchool',
    async ({ id }) => {
        try {
            const response = await axios.get(`http://localhost:8000/api/v1/school/${id}`)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const UpdateSchool = createAsyncThunk(
    'UpdateSchool',
    async (body) => {
        try {
            const response = await axios.put(`http://localhost:8000/api/v1/schools`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const DeleteSchools = createAsyncThunk(
    'DeleteSchools',
    async ({ id }) => {
        try {
            const response = await axios.delete(`http://localhost:8000/api/v1/schools?id=${id}`,)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const SchoolApproveAndDisapprove = createAsyncThunk(
    'SchoolApproveAndDisapprove',
    async ({ id, approval }) => {
        try {
            const response = await axios.put(`http://localhost:8000/api/v1/school/${id}`, { approval })
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const CollageBlogs = createAsyncThunk(
    'CollageBlogs',
    async ({ id }) => {
        try {
            const response = await axios.get(`http://localhost:8000/api/v1/college/blog/${id}`)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const CollageBlogsAdd = createAsyncThunk(
    'CollageBlogsAdd',
    async ({ id, blog, title }) => {
        try {
            const response = await axios.post(`http://localhost:8000/api/v1/college/blog/${id}`, { blog, title })
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)


const CollagesSlice = createSlice({
    name: "user",
    initialState: { collages: [], college: {}, schools: [], school: {} },
    reducers: {

    },
    extraReducers: {
        [AddCollage.fulfilled]: (state, { payload }) => {
            state.collages = payload.collages
        },
        [GetCollages.fulfilled]: (state, { payload }) => {
            state.collages = payload.collages
        },
        [GetCollage.fulfilled]: (state, { payload }) => {
            state.college = payload.college
        },
        [UpdateCollage.fulfilled]: (state, { payload }) => {
            state.collages = payload.collages
        },
        [DeleteCollages.fulfilled]: (state, { payload }) => {
            state.collages = payload.collages
        },
        [CollageApproveAndDisapprove.fulfilled]: (state, { payload }) => {
            state.collage = payload.collage
        },





        [AddSchool.fulfilled]: (state, { payload }) => {
            state.schools = payload.schools
        },
        [GetSchools.fulfilled]: (state, { payload }) => {
            state.schools = payload.schools
        },
        [GetSchool.fulfilled]: (state, { payload }) => {
            state.school = payload.school
        },
        [UpdateSchool.fulfilled]: (state, { payload }) => {
            state.schools = payload.schools
        },
        [DeleteSchools.fulfilled]: (state, { payload }) => {
            state.schools = payload.schools
        },
        [SchoolApproveAndDisapprove.fulfilled]: (state, { payload }) => {
            state.school = payload.school
        },


    }

}
)
export default CollagesSlice.reducer