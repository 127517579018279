
//admin complete
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from 'axios'

export const adminAddUser = createAsyncThunk(
    'adminAddUser',
    async ({ ...body }) => {
        try {
            const response = await axios.post("http://localhost:8000/api/v1/admin", body)
            return response.data
        } catch (err) {
            return err.response.data
        }


    }
)
export const adminLogin = createAsyncThunk(
    'adminLogin',
    async (body) => {
        try {
            const response = await axios.post("http://localhost:8000/api/v1/admin/login", body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const GetAdmin = createAsyncThunk(
    'GetAdmin',
    async () => {
        try {
            const response = await axios.get("http://localhost:8000/api/v1/admin",)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const updateAdmin = createAsyncThunk(
    'updateAdmin',
    async ({ ...body }) => {
        try {
            const response = await axios.put("http://localhost:8000/api/v1/admin", body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const DeleteAdmin = createAsyncThunk(
    'DeleteAdmin',
    async ({ id }) => {
        try {
            const response = await axios.delete(`http://localhost:8000/api/v1/admin?id=${id}`)
            return response.data
        } catch (err) {
            return err.response.data
        }
    }
)
export const TokenLogin = createAsyncThunk(
    'TokenLogin',
    async (body) => {
        try {
            const response = await axios.post("http://localhost:8000/api/v1/admin/token", body)
            return response.data
        } catch (err) {
            return err.response.data
        }
    }
)


export const AddSubscription = createAsyncThunk(
    'GetSubscription',
    async ({ admin, ...body }) => {
        try {
            const response = await axios.post("https://admin.digitalskillup.in/courses", body, {
            })
            return response.data
        } catch (err) {
            return err.response.data
        }


    }
)
export const AddBatch = createAsyncThunk(
    'GetSubscription',
    async ({ admin, ...body }) => {
        try {
            const response = await axios.post("https://admin.digitalskillup.in/batch", body, {
            })
            console.log("i am the one the one", response)
            return response.data
        } catch (err) {
            return err.response.data
        }


    }
)
export const GetSubscription = createAsyncThunk(
    'GetSubscription',
    async () => {
        try {
            const response = await axios.get("https://admin.digitalskillup.in/batch")
            return response
        } catch (err) {
            return err.response.data
        }

    }
)
export const GetCourse = createAsyncThunk(
    'GetSubscription',
    async () => {
        try {
            const response = await axios.get("https://admin.digitalskillup.in/courses")
            return response
        } catch (err) {
            return err.response.data
        }

    }
)
export const updateSubscription = createAsyncThunk(
    'updateSubscription',
    async ({ id, ...body }) => {
        try {
            const response = await axios.put(`https://admin.digitalskillup.in/batch/${id}`, body, {
            })
            console.log("response>data", response.data)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const updateCourse = createAsyncThunk(
    'GetSubscription',
    async ({ id, ...body }) => {
        try {
            const response = await axios.put(`https://admin.digitalskillup.in/courses/${id}`, body, {
            })
            console.log("response>data", response.data)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const DeleteSubscription = createAsyncThunk(
    'DeleteBatch',
    async ({ id }) => {
        try {
            const response = await axios.delete(`https://admin.digitalskillup.in/batch/${id}`, {
            })
            return response.data
        } catch (err) {
            return err.response.data
        }
    }
)


const AdminSlice = createSlice({
    name: "user",
    initialState: { adminAuth: true, admin: {}, admins: [], subscriptions: [] },
    reducers: {

    },
    extraReducers: {
        [adminLogin.fulfilled]: (state, { payload }) => {
            if (payload.success) {
                state.adminAuth = payload.success
                state.admin = payload.user
                localStorage.setItem("Token", payload.token)
            } else {
                alert(payload.message)
            }
        },
        [DeleteSubscription.fulfilled]: (state, { payload }) => {
            const batchData = payload?.batch
            console.log("paload?.success", payload.success)
            // console.log("batchData", batchData)
            const FiltredId = batchData.filter((data) => { console.log("data?.id", data?._id); console.log("payload.deleteBatch", payload.deleteBatch?._id); return data?._id !== payload.deleteBatch?._id })
            console.log("FiltredId", FiltredId)
            alert(payload.message)
            state.subscriptions = FiltredId
        },
        [GetSubscription.fulfilled]: (state, { payload }) => {
            // state.subscriptions = payload.data
            console.log("payload.message.............", payload)
            if (payload.message) {
                state.subscriptions = payload.batch
            } else {
                alert(payload.message)
            }
        },
        [updateSubscription.fulfilled]: (state, { payload }) => {
            console.log("payload.message.............", payload?.Batch)
            console.log("i am here")
            if (payload.message) {
                state.subscriptions = payload?.Batch
            } else {
                alert(payload.message)
            }
        },
        [TokenLogin.fulfilled]: (state, { payload }) => {
            if (payload.success) {
                state.adminAuth = payload.success
                state.admin = payload.user
            } else {
                alert(payload.message)
            }
        },
        [GetCourse.fulfilled]: (state, { payload }) => {
            if (payload.success) {
                state.subscriptions = payload.data
            } else {
                alert(payload.message)
            }
        }, [TokenLogin.fulfilled]: (state, { payload }) => {
            if (payload.success) {
                state.adminAuth = payload.success
                state.admin = payload.user
            } else {
                alert(payload.message)
            }
        },
        [updateAdmin.fulfilled]: (state, { payload }) => {
            if (payload.success) {
                state.admins = payload.admins
            } else {
                alert(payload.message)
            }
        },
        [adminAddUser.fulfilled]: (state, { payload }) => {
            if (payload.success) {
                state.admins = payload.admins
            } else {
                alert(payload.message)
            }
        },
        [DeleteAdmin.fulfilled]: (state, { payload }) => {
            if (payload.success) {
                state.admins = payload.admins
            } else {
                alert(payload.message)
            }
        },
        [GetAdmin.fulfilled]: (state, { payload }) => {
            if (payload.success) {
                state.admins = payload.admins
            } else {
                alert(payload.message)
            }
        }
    }
}
)
export default AdminSlice.reducer