import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
export const addCmsUser = createAsyncThunk(
    'addCmsUser',
    async (body) => {
        try {
            const response = await axios.post("http://localhost:8000/api/v1/cmsUser", body)
            return response.data
        } catch (err) {
            return err.response.data
        }
    }
)
export const getCmsUser = createAsyncThunk(
    'getCmsUser',
    async () => {
        try {
            const response = await axios.get("http://localhost:8000/api/v1/cmsUser")
            return response.data
        } catch (err) {
            return err.response.data
        }
    }
)
export const updateCmsUser = createAsyncThunk(
    'updateCmsUser',
    async (body) => {
        try {
            const response = await axios.put("http://localhost:8000/api/v1/cmsUser", body)
            return response.data
        } catch (err) {
            return err.response.data
        }
    }
)

const UserSlice = createSlice({
    name: "cmsUser",
    initialState: { user: [] },
    reducers: {



    },
    extraReducers: (builder) => {
        builder.addCase(addCmsUser.fulfilled, (state, { payload }) => {
            if (payload?.success) {
                state.user = payload.cmsUser
            } else {
                alert(payload.message)
            }
        }).addCase(getCmsUser.fulfilled, (state, { payload }) => {
            if (payload?.success) {
                state.user = payload.cmsUser
            } else {
                alert(payload.message)
            }
        }).addCase(updateCmsUser.fulfilled, (state, { payload }) => {
            if (payload?.success) {
                state.user = payload.cmsUser
            } else {
                alert(payload.message)
            }
        })

    },
})
export default UserSlice.reducer