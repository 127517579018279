import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios'
export const AddRole = createAsyncThunk(
    'AddRole',
    async ({ admin, ...body }) => {
        try {
            console.log(body)
            const response = await axios.post("http://localhost:8000/api/v1/role", body, {
                headers: {
                    'X-auth-id': admin
                }
            })
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const GetRole = createAsyncThunk(
    'GetRole',
    async ({ admin }) => {
        try {

            const response = await axios.get("http://localhost:8000/" + "api/v1/role", {
                headers: {
                    'X-auth-id': admin
                }
            })
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const DelRole = createAsyncThunk(
    'DelRole',
    async ({ id, admin }) => {
        try {
            const response = await axios.delete("http://localhost:8000/" + `api/v1/role?id=${id}`, {
                headers: {
                    'X-auth-id': admin
                }
            })
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const UpdateRole = createAsyncThunk(
    'UpdateRole',
    async ({ admin, ...body }) => {
        try {
            const response = await axios.put("http://localhost:8000/" + "api/v1/role", body, {
                headers: {
                    'X-auth-id': admin
                }
            })
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
const rollSlice = createSlice({
    name: "user",
    initialState: { roles: [] },
    reducers: {

    },
    extraReducers: {

        [GetRole.fulfilled]: (state, { payload }) => {
            if (payload.success) {
                state.roles = payload.roles
            }
            else {
                alert(payload?.message)
            }
        },
        [AddRole.fulfilled]: (state, { payload }) => {
            if (payload.success) {
                state.roles = payload.roles
            }
            else {
                alert(payload?.message)
            }
        },
        [UpdateRole.fulfilled]: (state, { payload }) => {
            if (payload.success) {
                state.roles = payload.roles
            }
            else {
                alert(payload?.message)
            }
        },
        [DelRole.fulfilled]: (state, { payload }) => {
            if (payload.success) {
                state.roles = payload.roles
            }
            else {
                alert(payload?.message)
            }
        },
    }

}
)
export default rollSlice.reducer