import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { AddSubscription, updateCategory } from "../../store/AdminReducer";
import axios from "axios";
import { color } from "chart.js/helpers";
import ClipLoader from "react-spinners/ClipLoader";

function CategoryModel({ closeModel, id, GetDatafromCategory }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { _id } = useSelector((State) => State.admin.admin);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState({
    image: categoryData?.photo,
  });
  //   console.log("admin?.image", admin?.image);

  useEffect(() => {
    GetData();
  }, []);

  const handleImageChange = (e) => {
    setAdmin({ ...admin, image: e.target.files[0] });
  };

  const handleForm = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };

  const GetData = () => {
    fetch(`https://admin.digitalskillup.in/category/${id}`, { method: "GET" })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCategoryData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // const AddCourse = () => {
  //   const formData = new FormData();
  //   formData.append("photo", admin.image);
  //   formData.append("name", admin.name);
  //   formData.append("description", admin.description);
  //   formData.append("price", admin.price);
  //   console.log("admin.name", admin.name);
  //   console.log("admin.description", admin.description);
  //   console.log("admin.price", admin.price);
  //   console.log("admin.image", admin.image);
  //   axios
  //     .post("https://admin.digitalskillup.in/courses", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     .then(() => {
  //       alert("Course added successfully!");
  //       setAdmin({
  //         name: "",
  //         description: "",
  //         price: "",
  //         image: null,
  //       });
  //     })
  //     .catch((error) => {
  //       alert("Failed to add course. Please try again.");
  //       console.error("Error:", error);
  //     });
  // };
  const updateCategory = () => {
    if (admin.image === null) {
      alert("please fill in");
      return;
    }
    if (categoryData.name === "") {
      alert("please fill in");
      return;
    }
    if (categoryData.description === "") {
      alert("please fill in");
      return;
    }
    if (categoryData.type === "") {
      alert("please fill in");
      return;
    }
    if (Number(categoryData.OffpricePercent) > 100) {
      alert("Discount Should be less than 100");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("name", categoryData.name);
    formData.append("type", categoryData.type);
    formData.append("description", categoryData.description);
    formData.append("photo", admin.image);

    // console.log("admin.name", categoryData.name);
    // console.log("admin.description", categoryData.description);
    // console.log("admin.price", categoryData.price);
    // console.log("admin.image", admin.image);
    // console.log("ckData", ckData);

    axios
      .put(`https://admin.digitalskillup.in/category/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        alert("Category updated successfully!");
        setLoading(false);
        GetDatafromCategory();
      })
      .catch((error) => {
        alert("Failed to add Category. Please try again.");
        console.error("Error:", error);
      });
  };
  return (
    <div
      style={{
        position: "fixed",
        top: "0px",
        left: "0px",
        background: "#000000b8",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // zIndex: "20",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
          height: "90%",
          backgroundColor: "white",
          marginTop: "15px",
          padding: "20px",
          gap: "15%",
          flexWrap: "wrap",
          rowGap: "15px",
        }}
      >
        <div
          style={{
            // padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10%",
            rowGap: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "rgb(66 123 107)",
            }}
          >
            <h2> Category</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="title"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "#000",
              }}
            >
              Name
            </label>
            <input
              onChange={(e) =>
                setCategoryData((prev) => {
                  return { ...prev, [e.target.name]: e.target.value };
                })
              }
              type="text"
              id="name"
              name="name"
              value={categoryData?.name}
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="type"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "#000",
              }}
            >
              Type
            </label>
            <input
              onChange={(e) =>
                setCategoryData((prev) => {
                  return { ...prev, [e.target.name]: e.target.value };
                })
              }
              type="text"
              id="type"
              name="type"
              value={categoryData?.type}
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
          >
            <label
              htmlFor="description"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "#000",
              }}
            >
              description
            </label>
            <input
              onChange={(e) =>
                setCategoryData((prev) => {
                  return { ...prev, [e.target.name]: e.target.value };
                })
              }
              type="text"
              id="description"
              name="description"
              value={categoryData?.description}
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
              }}
            />
          </div>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              minWidth: "300px",
              maxWidth: "500px",
              flex: "1",
            }}
            enctype="multipart/form-data"
          >
            <label
              htmlFor="Image"
              style={{
                fontWeight: "500",
                fontSize: "20px",
                cursor: "pointer",
                color: "#000",
              }}
            >
              Image
            </label>
            {/* <div style={{ height: "50px", width: "50px" }}>
              <img
                src={categoryData?.photo}
                style={{ height: "100%", width: "100%" }}
              />
            </div> */}
            <input
              // onChange={handleForm}
              type="file"
              id="courseImage"
              name="courseImage"
              onChange={handleImageChange}
              style={{
                padding: "6px 12px",
                fontWeight: "400",
                fontSize: "16px",
                color: "black",
              }}
            />
          </form>
          {loading ? (
            <div
              style={{
                padding: "6px 12px",
                cursor: "pointer",
              }}
            >
              <ClipLoader
                color={"rgb(66 123 107)"}
                loading={loading}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div
              // onClick={() =>
              //   dispatch(
              //     updateCategory({
              //       id: categoryData._id,
              //       name: categoryData?.name,
              //       description: categoryData?.description,
              //       price: categoryData?.price,
              //       longDecription: ckData,
              //       photo: admin?.image,
              //     })
              //   )
              // }
              onClick={updateCategory}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                gap: "10px",
                maxWidth: "200px",
                flex: "1",
              }}
            >
              <div
                style={{
                  padding: "6px 12px",
                  fontWeight: "400",
                  fontSize: "16px",
                  boxShadow: "2px 2px 2px rgba(0,0,0,0.1)",
                  borderWidth: "3px",
                  textAlign: "center",
                  cursor: "pointer",
                  background: "rgb(88 155 136)",
                  color: "#fff",
                }}
              >
                Submit
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            // height: "40%",
            width: "40%",
            position: "absolute",
            top: "10%",
            left: "85%",
            // alignItems: "flex-end",
          }}
        >
          <div
            onClick={closeModel}
            style={{
              padding: "8px 15px",
              background: "rgb(88 155 136)",
              color: "#fff",
              fontWeight: "500",
              borderRadius: "3px",
              cursor: "pointer",
              display: "flex",
              width: "12%",
              textAlign: "center",
            }}
          >
            Close
          </div>
          <div
            style={{
              color: "black",
              overflow: "auto",
              maxHeight: "500px",
            }}
          >
            {/* <div
              dangerouslySetInnerHTML={{ __html: ckData?.longDecription }}
              style={{
                padding: "40px 20px",
                border: "1px solid black",
                color: "black",
              }}
            /> */}
            {/* <CKEditor
              editor={ClassicEditor}
              data={categoryData?.longDecription}
              onChange={(event, editor) => {
                const data = editor.getData();
                setCkData(data);
              }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryModel;
