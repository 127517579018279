import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios'

export const AddBlogs = createAsyncThunk(
    'AddBlog',
    async (body) => {
        try {
            const response = await axios.post(` http://localhost:8000/api/v1/Blog/new`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const GetBlog = createAsyncThunk(
    'GetBlogs',
    async () => {
        try {
            const response = await axios.get(` http://localhost:8000/api/v1/Blog`)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const DelBlog = createAsyncThunk(
    'DelBlog',
    async (body) => {
        try {
            const response = await axios.post(` http://localhost:8000/api/v1/Blogs/del`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const UpdateBlog = createAsyncThunk(
    'UpdateBlog',
    async (body) => {
        try {
            const response = await axios.post(` http://localhost:8000/api/v1/Blogs/update`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)



const BlogSlice = createSlice({
    name: "user",
    initialState: { blog: [] },
    reducers: {

    },
    extraReducers: {
        [AddBlogs.fulfilled]: (state, { payload }) => {
            state.blog = payload.blogs
        }, [DelBlog.fulfilled]: (state, { payload }) => {
            state.blog = payload.blogs
        },
        [GetBlog.fulfilled]: (state, { payload }) => {
            state.blog = payload.blogs
        }, [UpdateBlog.fulfilled]: (state, { payload }) => {
            state.blog = payload.blogs
        },

    }

}
)
export default BlogSlice.reducer