import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios'

export const addBoard = createAsyncThunk(
    'addBoard',
    async (body) => {
        try {
            const response = await axios.post(`http://localhost:8000/api/v1/category/board`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const getBoard = createAsyncThunk(
    'getBoard',
    async (body) => {
        try {
            const response = await axios.get(`http://localhost:8000/api/v1/category/board`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const updateBoard = createAsyncThunk(
    'updateBoard',
    async (body) => {
        try {
            const response = await axios.put(`http://localhost:8000/api/v1/category/board`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const deleteBoard = createAsyncThunk(
    'deleteBoard',
    async (body) => {
        try {
            console.log(body)
            const response = await axios.delete(`http://localhost:8000/api/v1/category/board?id=${body.id}`,)

            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const addCourse = createAsyncThunk(
    'addCourse',
    async (body) => {
        try {
            const response = await axios.post(`http://localhost:8000/api/v1/category/course`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const getCourse = createAsyncThunk(
    'getCourse',
    async (body) => {
        try {
            const response = await axios.get(`http://localhost:8000/api/v1/category/course`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const updateCourse = createAsyncThunk(
    'updateCourse',
    async (body) => {
        try {
            const response = await axios.put(`http://localhost:8000/api/v1/category/course`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const deleteCourse = createAsyncThunk(
    'deleteCourse',
    async (body) => {
        try {
            const response = await axios.delete(`http://localhost:8000/api/v1/category/course?id=${body.id}`,)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const addCountry = createAsyncThunk(
    'addCountry',
    async (body) => {
        try {
            const response = await axios.post(`http://localhost:8000/api/v1/category/country`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const getCountry = createAsyncThunk(
    'getCountry',
    async (body) => {
        try {
            const response = await axios.get(`http://localhost:8000/api/v1/category/country`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const updateCountry = createAsyncThunk(
    'updateCountry',
    async (body) => {
        try {
            const response = await axios.put(`http://localhost:8000/api/v1/category/country`, body)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)
export const deleteCountry = createAsyncThunk(
    'deleteCountry',
    async (body) => {
        try {
            const response = await axios.delete(`http://localhost:8000/api/v1/category/country?id=${body.id}`,)
            return response.data
        } catch (err) {
            return err.response.data
        }

    }
)

const categorySlice = createSlice({
    name: "category",
    initialState: { board: [], course: [], country: [] },
    reducers: {

    },
    extraReducers: {
        [addBoard.fulfilled]: (state, { payload }) => {
            state.board = payload.board
        },
        [getBoard.fulfilled]: (state, { payload }) => {
            state.board = payload.board
        },
        [updateBoard.fulfilled]: (state, { payload }) => {
            state.board = payload.board
        },
        [deleteBoard.fulfilled]: (state, { payload }) => {
            state.board = payload.board
        },
        [addCourse.fulfilled]: (state, { payload }) => {
            state.course = payload.course
        },
        [getCourse.fulfilled]: (state, { payload }) => {
            state.course = payload.course
        },
        [updateCourse.fulfilled]: (state, { payload }) => {
            state.course = payload.course
        },
        [deleteCourse.fulfilled]: (state, { payload }) => {
            state.course = payload.course
        },
        [addCountry.fulfilled]: (state, { payload }) => {
            state.country = payload.country
        },
        [getCountry.fulfilled]: (state, { payload }) => {
            state.country = payload.country
        },
        [updateCountry.fulfilled]: (state, { payload }) => {
            state.country = payload.country
        },
        [deleteCountry.fulfilled]: (state, { payload }) => {
            state.country = payload.country
        },
        // [DelCategory.fulfilled]: (state, { payload }) => {
        //     state.category = payload.category
        // }, [UpdateCategory.fulfilled]: (state, { payload }) => {
        //     state.category = payload.category
        // },
        // [GetCategory.fulfilled]: (state, { payload }) => {
        //     state.category = payload.AllCategory
        // },
    }

}
)
export default categorySlice.reducer