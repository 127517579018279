import './App.css';
import { Route, Routes } from 'react-router-dom';
import Sidebar from './Components/Component/SidebarComponent/Sidebar';
import Dashboard from './Components/Pages/Dashboard';
// import CollagesPage from './Components/Pages/Collages';
// import AdminUser from './Components/Pages/AdminUser';
// import Category from './Components/Pages/Category';
// import Role from './Components/Pages/Role';
// import AddBlog from './Components/Pages/AddBlog';
// import ExamPage from './Components/Pages/ExamPage';
// import AllExamPage from './Components/Pages/AllExamPage';
// import MyCollage from './Components/Pages/MyCollage';
// import ListingPage from './Components/Pages/ListingPage';
// import CmsUser from './Components/Pages/CmsUser';
// import CollagePage from './Components/Pages/CollagePage';
// import Schools from './Components/Pages/Schools';
// import School from './Components/Pages/School';
// import LeadView from './Components/Pages/LeadView';
// import AddAdmin from './Components/Pages/AddAdmin';
import UserView from './Components/Pages/UserView';
import Subscription from './Components/Pages/Subscription';
import SubscriptionAdd from './Components/Pages/SubscriptionAdd';
import Lead from './Components/Pages/LeadComponent/Lead';
import ContactUs from './Components/Pages/ContactusComp/ContactUs';
import BatchAdd from './Components/Pages/BatchAdd';
import Changepass from './Components/Pages/ChangePassComponent/Changepass';
import CourseCategory from './Components/Pages/CourseCategory';
import CourseCategoryAdd from './Components/Pages/CourseCategoryAdd';
import CourseFAQ from './Components/Pages/CourseFAQ';
import AddCourseFaq from './Components/Pages/AddCourseFaq';
import Blogs from './Components/Pages/Blogs';
import BlogAdd from './Components/Pages/BlogAdd';
import Enrolledteacher from './Components/Pages/Enrolledteacher';
function App() {
  return (
    <Routes>
      <Route path="/*" element={<Sidebar >
        <Routes>
          <Route path="/" element={<Dashboard />} />
          {/* <Route path="/admin-user" element={<AdminUser />} />
          <Route path="/admin-user/add" element={<AddAdmin />} />
          <Route path="/role" element={<Role />} /> */}
          <Route path="/course" element={<Subscription />} />
          <Route path="/category" element={<CourseCategory />} />
          <Route path="/courseFAQ" element={<CourseFAQ />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/BlogAdd" element={<BlogAdd />} />
          <Route path="/AddCourseFAQ" element={<AddCourseFaq />} />
          <Route path="/lead" element={<Lead />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/EnrolledTeacher" element={<Enrolledteacher />} />
          <Route path="/changepassword" element={<Changepass />} />
          <Route path="/course/add" element={<SubscriptionAdd />} />
          <Route path="/category/add" element={<CourseCategoryAdd />} />
          <Route path="/batch/add" element={<BatchAdd />} />


          <Route path="/batch" element={<UserView />} />
          {/* <Route path="/category" element={<Category />} />
          <Route path="/cms-user" element={<CmsUser />} />
          <Route path="/collages" element={<CollagesPage />} />
          <Route path="/collage/:id" element={<CollagePage />} />
          <Route path="/school" element={<Schools />} />
          <Route path="/school/:id" element={<School />} />
          <Route path="/lead/:id" element={<LeadView />} /> */}
        </Routes>
      </Sidebar>}>
      </Route >
    </Routes >
  );
}
export default App;

